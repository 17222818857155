import * as THREE from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { Vertex, Edge, Face, UniqueVertex } from "./Geo";
import { UVUnwrapper } from "xatlas-three";
import { BufferGeometry, Vector2 } from "three";
import { Chart, ChartLSCMSolve } from "./Chart";
import { face_uv_area_signed } from "./Utils";
import { AppSettings } from "../AppSettings";

export class UVMapper {
  initialized = false;
  arrayIndex:Array<number> = [];
  countVertices:number = 0;
  faceMap: Array<Face> = [];
  vertexMap: Array<Vertex> = [];
  uniqueVerticesMap: Array<UniqueVertex> = [];
  edgeMap: Array<Edge> = [];
  chartMaps: Array<Chart> = [];

  constructor(geometry:BufferGeometry|null, seams:boolean = true) {
    if(geometry !=null)
    {
      this.init(geometry, seams);
    }
  }
  init(geometry:BufferGeometry, seams:boolean) {
    if(geometry.index != null)this.arrayIndex = Array.prototype.slice.call(geometry.index.array);
    this.countVertices = geometry.getAttribute("position").array.length / 3;
    let positions = geometry.getAttribute("position").array;

    let normals = geometry.getAttribute("normal").array;
    let uv = geometry.getAttribute("uv").array;
    if(AppSettings.DEBUG)console.log(this.arrayIndex);
    this.faceMap = [];
    this.vertexMap = [];
    this.uniqueVerticesMap = [];
    this.edgeMap = [];

    for (let vertex = 0; vertex < this.countVertices; vertex++) {
      this.vertexMap.push(
        new Vertex(
          new THREE.Vector3(
            positions[vertex * 3],
            positions[vertex * 3 + 1],
            positions[vertex * 3 + 2]
          ),
          new THREE.Vector2(uv[vertex * 2], uv[vertex * 2 + 1]),
          new THREE.Vector3(
            normals[vertex * 3],
            normals[vertex * 3 + 1],
            normals[vertex * 3 + 2]
          ),
          vertex
        )
      );

      this.initUniqueVertices(this.vertexMap[vertex]);
    }

    if(geometry.index != null)
    {
      
      for (
        let faceIndex = 0;
        faceIndex < this.arrayIndex.length / 3;
        faceIndex++
      ) {
        let vertex1 = this.vertexMap[this.arrayIndex[faceIndex * 3]];
        let vertex2 = this.vertexMap[this.arrayIndex[faceIndex * 3 + 1]];
        let vertex3 = this.vertexMap[this.arrayIndex[faceIndex * 3 + 2]];
  
        vertex1.addFace(faceIndex);
        vertex2.addFace(faceIndex);
        vertex3.addFace(faceIndex);
  
        this.faceMap.push(new Face(vertex1, vertex2, vertex3, faceIndex));
      }
    }
    else
    {
      console.warn("Geometry index is null");
      for (
        let faceIndex = 0;
        faceIndex < this.countVertices / 3;
        faceIndex++
      ) {
        let vertex1 = this.vertexMap[3 * faceIndex];
        let vertex2 = this.vertexMap[3 * faceIndex + 1];
        let vertex3 = this.vertexMap[3 * faceIndex + 2];
  
        vertex1.addFace(faceIndex);
        vertex2.addFace(faceIndex);
        vertex3.addFace(faceIndex);
  
        this.faceMap.push(new Face(vertex1, vertex2, vertex3, faceIndex));
      }   
    }

    let edge = null;
    for (let faceIndex = 0; faceIndex < this.faceMap.length; faceIndex++) {
      for (let faceIndex2 = 0; faceIndex2 < this.faceMap.length; faceIndex2++) {
        edge = this.faceMap[faceIndex].getCommonEdgeIndex(
          this.faceMap[faceIndex2]
        );
        if (faceIndex != faceIndex2 && edge != null) {
          this.faceMap[faceIndex].setOppositeFaceIndex(
            edge.i,
            this.faceMap[faceIndex2].indexFace
          );
        }
      }
    }
    this.solveTriEdges();
    
    if(seams)this.checkEdgeSeams();
    
    
    for (let faceIndex = 0; faceIndex < this.faceMap.length; faceIndex++) {
      for (
        let edgeIndex = 0;
        edgeIndex < this.faceMap[faceIndex].edges.length;
        edgeIndex++
      ) {
        this.edgeMap.push(this.faceMap[faceIndex].edges[edgeIndex]);
        this.addEdgeToUniqueVertices(
          this.faceMap[faceIndex],
          this.faceMap[faceIndex].edges[edgeIndex]
        );
      }
    }

    if(AppSettings.DEBUG)console.log(this.faceMap);
    if(AppSettings.DEBUG)console.log(this.uniqueVerticesMap);
    this.initialized = true;
    for (let i = 0; i < this.uniqueVerticesMap.length; i++) {
      this.uniqueVerticesMap[i].computeEdges();
    }


    if(!seams)
    {
      for (let i = 0; i < this.uniqueVerticesMap.length; i++) {
        this.uniqueVerticesMap[i].sewUV();
      }
    }
  }
  
  sewAll()
  {
    for (let i = 0; i < this.uniqueVerticesMap.length; i++) {
      this.uniqueVerticesMap[i].sewAll();
      this.uniqueVerticesMap[i].sewUV();
    }
  }
  //#region Functions
  addEdgeToUniqueVertices(face:Face, edge:Edge) {

    for (let i = 0; i < this.uniqueVerticesMap.length; i++) {
      if (this.uniqueVerticesMap[i].equalsPosition(edge.vertex1)) {
        this.uniqueVerticesMap[i].addVertexEdge(edge.vertex1, edge, face);
      } else if (this.uniqueVerticesMap[i].equalsPosition(edge.vertex2)) {
        this.uniqueVerticesMap[i].addVertexEdge(edge.vertex2, edge, face);
      } 
    }
  }
  
  initUniqueVertices(vertex:Vertex) {

    if (this.uniqueVerticesMap.length == 0)
      this.uniqueVerticesMap.push(new UniqueVertex(0, vertex.position));
    else {
      let alreadyExists = false;
      for (let i = 0; i < this.uniqueVerticesMap.length; i++) {
        if (this.uniqueVerticesMap[i].equalsPosition(vertex)) {
          alreadyExists = true;
          break;
        }
      }

      if (!alreadyExists) {
        this.uniqueVerticesMap.push(
          new UniqueVertex(this.uniqueVerticesMap.length, vertex.position)
        );
      }
    }
  }


  checkEdgeSeams() {

    let edge = null;
    for (let faceIndex = 0; faceIndex < this.faceMap.length; faceIndex++) {
      for (let faceIndex2 = 0; faceIndex2 < this.faceMap.length; faceIndex2++) {
        edge = this.faceMap[faceIndex].getCommonEdgeIndex(
          this.faceMap[faceIndex2]
        );
        if (faceIndex != faceIndex2 && edge != null) {
          this.faceMap[faceIndex].setupEdgeSeams(
            edge.i,
            this.faceMap[faceIndex2].edges[edge.j]
          );
        }
      }
    }
  }

  updateUVGeometry(mesh:THREE.Mesh) {
    let newGeometry = new THREE.BufferGeometry();
    let vertices:Array<number> = [];
    let uv:Array<number> = [];
    let normal:Array<number> = [];

    for (let faceIndex = 0; faceIndex < this.faceMap.length; faceIndex++) {
      let verticesFace = this.faceMap[faceIndex].getVertices();
      for (let vertex = 0; vertex < 3; vertex++) {
        vertices = vertices.concat(verticesFace[vertex].getPositionPoints());
        uv = uv.concat(verticesFace[vertex].getUVPoints());
        normal = normal.concat(verticesFace[vertex].getNormalPoints());
      }
    }

    newGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );
    newGeometry.setAttribute("uv", new THREE.Float32BufferAttribute(uv, 2));
    newGeometry.setAttribute(
      "normal",
      new THREE.Float32BufferAttribute(normal, 3)
    );

    mesh.geometry = BufferGeometryUtils.mergeVertices(newGeometry);


  }


  chartingUVMap() {

    let faceArray = Array.from(Array(this.faceMap.length).keys());
    let chartFaces = [];
    let chartIndex = 0;
    this.chartMaps = [];
    while (faceArray.length > 0) {
      chartFaces = [];

      chartFaces = this.exploreFaceChart(
        faceArray.splice(0, 1)[0],
        faceArray
      );

      this.chartMaps.push(new Chart(chartIndex));
      while (chartFaces.length > 0) {
        this.chartMaps[chartIndex].addFace(this.faceMap[chartFaces.splice(0, 1)[0]]);
      }

      this.chartMaps[chartIndex].calculate();
      chartIndex++;
    }
    this.solveUVMap();

    
  }

  solveUVMap()
  {
    for(let i = 0; i< this.chartMaps.length;i++)
    {
      let chart = this.chartMaps[i];
      
      if(chart.chartLSCM?.context)
      {
        if(AppSettings.DEBUG)console.log("SOLVING");
        if(AppSettings.DEBUG)console.log(chart);
        const result =  ChartLSCMSolve(chart);
        chart.applyUVToVertices();
        chart.rotateMinimumArea();
        if(AppSettings.DEBUG)console.log(chart.vertices);
        chart.chartLSCM = undefined;
       
      }
    }
    this.averageCharts();
    let maxSide = 0;
    let totSide = 0;
    for( let chart of this.chartMaps)
    {
      maxSide = Math.max(Math.max(chart.chartPack.size.x,chart.chartPack.size.y), maxSide);
      totSide+=Math.max(chart.chartPack.size.x,chart.chartPack.size.y);
    }
    for(let i = 0; i< this.chartMaps.length;i++)
    {
      let side = Math.max(this.chartMaps[i].chartPack.size.x,this.chartMaps[i].chartPack.size.y);

      this.chartMaps[i].uvScale(1 /  side  * (side/totSide) * 0.9 );
      let trans = new Vector2().subVectors(new Vector2(0.5,0.5),this.chartMaps[i].chartPack.origin);

      this.chartMaps[i].uvTranslate(trans);
    }
    

    this.packCharts();
    if(AppSettings.DEBUG)console.log(this.chartMaps);
  }

  exploreFaceChart(faceIndex:number, faceArray:Array<number>) {

    let neighbors = this.faceMap[faceIndex].getNeighborsIndex();

    let chartFaces = [faceIndex];
    for (let i = 0; i < neighbors.length; i++) {
      let neighbor = neighbors[i];

      if (neighbor != null  && faceArray.indexOf(neighbor) != -1) {
        faceArray.splice(faceArray.indexOf(neighbor), 1);
        chartFaces = chartFaces.concat(
          this.exploreFaceChart(neighbor, faceArray)
        );
      }
    }
    return chartFaces;
  }

  solveTriEdges() {
    for (let faceIndex = 0; faceIndex < this.faceMap.length; faceIndex++) {
      let diagFaces = []
      for (let faceIndex2 = 0; faceIndex2 < this.faceMap.length; faceIndex2++) {

        if (this.faceMap[faceIndex].commonEdgeDone) break;

        if (
          faceIndex != faceIndex2 &&
          this.faceMap[faceIndex].isCoplanar(this.faceMap[faceIndex2])
            && this.faceMap[faceIndex].getCommonEdgeIndex(this.faceMap[faceIndex2])
        ) {
          diagFaces.push(this.faceMap[faceIndex2]);
        }

      }

      if(diagFaces.length == 1)
      {
        this.faceMap[faceIndex].disableCommonEdgeTri(
          diagFaces[0]
        );
      }
    }
  }

  setEdgeSeam(vertexCount:number, booleanState:boolean) {
    this.edgeMap[vertexCount].edgeSeam = booleanState;
  }

  averageCharts()
  {

    let tot_uvarea = 0.0, tot_facearea = 0.0;
    let tot_fac, fac;
  
    if (this.chartMaps.length == 0) {
      return;
    }
  
    for (let i = 0 ; i < this.chartMaps.length ; i++) {
      let chart = this.chartMaps[i];
      

      chart.chartPack.origin.addVectors(chart.minV, chart.maxV).multiplyScalar(0.5);
      
      //Pas de scaleUV ou shear

      chart.chartPack.area = 0.0;    /* 3d area */
      chart.chartPack.rescale = 0.0; /* UV area, abusing rescale for tmp storage, oh well :/ */
      
      for( let face of chart.faces)
      {
        chart.chartPack.area += face.calculateArea();
        chart.chartPack.rescale += Math.abs(face_uv_area_signed(face));
      }
      tot_facearea += chart.chartPack.area;
      tot_uvarea += chart.chartPack.rescale;
    }
    
    if (tot_facearea == tot_uvarea || tot_facearea == 0.0 || tot_uvarea == 0.0) {
      return;
    }
    
    tot_fac = tot_facearea / tot_uvarea;
    
    if(AppSettings.DEBUG)console.log("tot_fac", tot_fac);
    for (let i = 0 ; i < this.chartMaps.length ; i++) {
      let chart = this.chartMaps[i];
      
      if (chart.chartPack.area != 0.0 && chart.chartPack.rescale != 0.0) {
        fac = chart.chartPack.area / chart.chartPack.rescale;

        /* Average scale*/
        chart.uvScale(fac / tot_fac);
        /* Get the current island center. */
        chart.calculateCurrentCenter();
        /* Move to original center. */
        let trans = chart.uvCentroid.clone().negate().add(chart.chartPack.origin);
        chart.uvTranslate(trans);
      }
    }

  }
  
  packCharts()
  {

    let maxWidth = 0;
    for (let chart of this.chartMaps)
    {
      maxWidth = Math.max(maxWidth, chart.chartPack.size.x);
    }

    this.chartMaps.sort((a, b) => b.chartPack.size.y - a.chartPack.size.y);
    

    let spaces = [{x: 0.01, y: 0.01, u: 0.99, v: 0.99}];
    let packed = [];
    const padding = 1.03;
    for (let i = 0; i<this.chartMaps.length; i++)
    {
      let chart = this.chartMaps[i];
      {
        for (let i = spaces.length - 1; i >= 0; i--) {
          const space = spaces[i];
    
          // look for empty spaces that can accommodate the current chart
          if (chart.chartPack.size.x > space.u || chart.chartPack.size.y > space.v) continue;
    
          // found the space; add the chart to its top-left corner
          // |-------|-------|
          // |  chart  |       |
          // |_______|       |
          // |         space |
          // |_______________|
          packed.push({idChart: i,origin: new Vector2(space.x,space.y), size: new Vector2(chart.chartPack.size.x,chart.chartPack.size.y)});
    
          if (chart.chartPack.size.x === space.u && chart.chartPack.size.y === space.v) {
            // space matches the chart exactly; remove it
            const last = spaces.pop();
            if (i < spaces.length) spaces[i] = last!;
    
          } else if (chart.chartPack.size.y === space.v) {
            // space matches the chart height; update it accordingly
            // |-------|---------------|
            // |  chart  | updated space |
            // |_______|_______________|
            space.x += chart.chartPack.size.x  * padding;
            space.u -= chart.chartPack.size.x  * padding;
    
          } else if (chart.chartPack.size.x === space.u) {
            // space matches the chart width; update it accordingly
            // |---------------|
            // |      chart      |
            // |_______________|
            // | updated space |
            // |_______________|
            space.y += chart.chartPack.size.y  * padding;
            space.v -= chart.chartPack.size.y  * padding;
    
          } else {
            // otherwise the chart splits the space into two spaces
            // |-------|-----------|
            // |  chart  | new space |
            // |_______|___________|
            // | updated space     |
            // |___________________|
            spaces.push({
              x: space.x + chart.chartPack.size.x* padding,
              y: space.y* padding,
              u: (space.u - chart.chartPack.size.x) * padding ,
              v: chart.chartPack.size.y  * padding
            });
            space.y += chart.chartPack.size.y * padding;
            space.v -= chart.chartPack.size.y  * padding;
          }
          break;
        }
      }
    }
    if(AppSettings.DEBUG)console.log("spaces", spaces);  
    if(AppSettings.DEBUG)console.log(packed);
    for(let pack of packed)
    {
      //on centre
      let newOrigin = pack.origin.clone().add(pack.size.clone().multiplyScalar(0.5));
      let trans = this.chartMaps[pack.idChart].chartPack.origin.clone().negate().add(newOrigin);
      if(AppSettings.DEBUG)console.log("Origins", pack.idChart);
      if(AppSettings.DEBUG)console.log(newOrigin);
      if(AppSettings.DEBUG)console.log(this.chartMaps[pack.idChart].chartPack.origin);
      if(AppSettings.DEBUG)console.log(trans);
      this.chartMaps[pack.idChart].uvTranslate(trans);
    }
  }

}

const unwrapper = new UVUnwrapper({ BufferAttribute: THREE.BufferAttribute });
// Default options
unwrapper.chartOptions = {
  fixWinding: true,
  maxBoundaryLength: 0,
  maxChartArea: 0,
  maxCost: 100,
  maxIterations: 2,
  normalDeviationWeight: 2,
  normalSeamWeight: 4,
  roundnessWeight: 0.009999999776482582,
  straightnessWeight: 6,
  textureSeamWeight: 1,
  useInputMeshUvs: false,
};
unwrapper.packOptions = {
  bilinear: true,
  blockAlign: false,
  bruteForce: false,
  createImage: false,
  maxChartSize: 0,
  padding: 2,
  resolution: 0,
  rotateCharts: true,
  rotateChartsToAxis: true,
  texelsPerUnit: 0,
};
