var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BaseXAtlas } from "./baseXAtlas";
import { proxy, wrap } from "comlink";
export class XAtlasWebWorker extends BaseXAtlas {
    init(onLoad, onProgress, wasmFilePath, workerFilePath) {
        if (this.api)
            return;
        if (!workerFilePath)
            throw new Error("workerFilePath is required");
        (() => __awaiter(this, void 0, void 0, function* () {
            const workerCode = yield fetch(workerFilePath).then(res => res.blob());
            const workerUrl = URL.createObjectURL(workerCode);
            const t = new Worker(workerUrl, { type: 'module', });
            // @ts-ignore
            this.api = yield (new (wrap(t))(proxy(() => {
                onLoad();
                URL.revokeObjectURL(workerUrl);
            }), proxy((path, dir) => {
                return (path === "xatlas_web.wasm" ? wasmFilePath : path + dir);
            }), proxy(onProgress)));
        }))();
    }
}
