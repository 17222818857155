import { Props } from "@react-three/fiber";
import { render } from "@testing-library/react";
import { Component, createRef, forwardRef, RefObject, useEffect, useRef } from "react";
import { EdgeInteraction, EdgeInteractionProps } from "./EdgeInteraction";
import Button from 'react-bootstrap/Button';
import {withTranslation} from "react-i18next";

export class SidePanel extends Component{

    
    toolCutButton:React.RefObject<HTMLButtonElement>;
    toolSewButton:React.RefObject<HTMLButtonElement>
    toolUnfoldButton: React.RefObject<HTMLButtonElement>;
    labelSeams: React.RefObject<HTMLSpanElement>;
    labelStretch : React.RefObject<HTMLSpanElement>;
    callFunction : ()=>void;
    public state:{globalStretchness:number,edgeSeams:number, stretchGoal:number|null, seamsGoal:number|null} ={
        globalStretchness:0,
        edgeSeams:0,
        stretchGoal:null,
        seamsGoal:null
    }


    constructor(props:Props, stats:{globalStretchness:number,edgeSeams:number, stretchGoal:number|null, seamsGoal:number|null})
    {
        super(props);
        this.toolCutButton = createRef<HTMLButtonElement>();
        this.toolSewButton = createRef<HTMLButtonElement>();
        this.toolUnfoldButton = createRef<HTMLButtonElement>();
        this.labelSeams = createRef<HTMLSpanElement>();
        this.labelStretch = createRef<HTMLSpanElement>();
        this.state = stats;
        this.callFunction = ()=>{};

    }

    setEdgeSeams(numberEdgeSeams:number)
    {
        this.setState({edgeSeams : numberEdgeSeams});
    }
    
    setGlobalStretchness(stretchness:number)
    {
        if(Number.isNaN(stretchness))return;
        this.setState({globalStretchness : stretchness.toFixed(2)});
  
    }

    setGoal(goalStretch:number|null, goalSeams:number|null)
    {
        this.setState({stretchGoal:goalStretch, seamsGoal:goalSeams})
    }
    
    setFunction(practiceFunction:()=>void)
    {
        this.callFunction = practiceFunction;
    }

    render() 
    {
        
        let spanGoalSeams, spanGoalStretch;
        let str = "<="
        let next = true;
        let className = "btnPrimary"
        if(this.state.seamsGoal)
        {
            spanGoalSeams = <span id = "Edgeseams" >Edgeseams {str} <b>{this.state.seamsGoal}</b> </span>
            if( this.state.edgeSeams > this.state.seamsGoal)next = false;
        }

        if(this.state.stretchGoal)
        {
            spanGoalStretch = <span id = "Stretch" >Stretch {str} <b>{this.state.stretchGoal}</b></span>
            if( this.state.globalStretchness > this.state.stretchGoal)next = false;
        }

        if(!next)
        {
            className = "btnBlocked"
        }
        return(
            
            <div id ="ToolSidepanel" className="sidepanel" >
                <div  style={{alignSelf:"center"}}>
                    <button className={className} id="ToolUnfold" onClick={this.callFunction}>Next</button>
                </div>
                <div className ="separatorSide"/>
                <div id="ToolsMenu">
                    <h1 id="ToolTitle">Tools</h1>
                    <div id="ToolsContent">
                        <div style={{width:"100%"}} className ="btnSpan">
                            <button className="btnPrimary" id="ToolCut" ref={this.toolCutButton}>Cut</button>
                            <span className="desktopOnly" >Key : <b>X</b></span>
                        </div>
                        <div style={{width:"100%"}} className ="btnSpan">
                            <button className="btnPrimary" id="ToolSew" ref={this.toolSewButton}>Sew</button>
                            <span className="desktopOnly" >Key : <b>C</b></span>
                        </div>
                        
                        <div style={{width:"100%"}} className ="btnSpan">
                            <button className="btnPrimary" id="ToolUnfold" ref={this.toolUnfoldButton}>Unfold</button>
                            <span className="desktopOnly" >Key : <b>V</b></span>
                        </div>
                        <span className="desktopOnly" style={{marginTop:"1vh"}}>Hold <b>SHIFT</b> to select multiple edges</span>
                        <span className="desktopOnly" style={{marginTop:"1vh"}}>Press <b>F</b> to reset view</span>
                    </div>
                </div>
                <div className ="separatorSide"/>
                <div id="Stats">
                <h1 id="StatsTitle"> Stats (Goals) </h1>
                    <div id="StatsContent">
                        <span id = "GoalExplain">Try to make less or equal to the goals in paranthesis !</span>
                        <span id = "Edgeseams" ref={this.labelSeams} >Edgeseams : <b>{this.state.edgeSeams}</b> ({this.state.seamsGoal}) </span>
                        <span id = "Stretch" ref={this.labelStretch}>Stretch : <b>{this.state.globalStretchness}</b> ({this.state.stretchGoal})</span>
                    </div>

                </div>

                
                
            </div>
            );
    }
}
