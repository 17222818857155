import { ReactThreeFiber, ThreeElements, ThreeEvent, useFrame, useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { useEffect, useRef, useCallback, RefObject, createRef, forwardRef, ForwardRefExoticComponent } from "react";
import { BufferGeometry, Color, GeometryUtils, Object3D, Vector2} from "three";
import {UVMapper} from '../UVMapper'
import { Edge } from "../Geo";
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { useTexture } from "@react-three/drei";
import * as uvTexture from "../../CheckerboardTex.png";
import { SidePanel } from "./SidePanel";
import { AppSettings } from "../../AppSettings";

const pointUser = new THREE.Vector2();
export let shiftPressed = false;
export let diffX = 0,
  diffY = 0,
  delta = 5;
const distanceThreshold = 0.15;
const COLOR_EDGE = new THREE.Color(0x777777);
const COLOR_SELECTED = new THREE.Color(0xee0000);
const COLOR_SEAM = new THREE.Color(0x00ff00);
const COLOR_NORMAL = new THREE.Color(0x0000aa);

const DEBUG = false;
const imageUVTexture = new Image();
imageUVTexture.src = uvTexture.default;
let wireframe:Array<Line2> = [];
let canvasContext:CanvasRenderingContext2D | null;

export type EdgeInteractionProps  = Omit<ReactThreeFiber.Overwrite<ReactThreeFiber.Object3DNode<THREE.Group, typeof THREE.Group>, {
  geometry : BufferGeometry,
  canvas2DObject : HTMLCanvasElement,
  sidePanel : React.RefObject<SidePanel>,
  goals : {stretchGoal:number|null, seamsGoal:number|null},
  resetButton : React.RefObject<HTMLButtonElement>,
  practiceFunction : () => void;
}>, 'ref'>;

export const EdgeInteraction = forwardRef<EdgeInteractionProps,EdgeInteractionProps & React.RefAttributes<THREE.Group>>((props:{geometry : BufferGeometry,
  canvas2DObject : HTMLCanvasElement,
  sidePanel : React.RefObject<SidePanel>,
  goals : {stretchGoal:number|null, seamsGoal:number|null},
  practiceFunction : () => void,
  resetButton : React.RefObject<HTMLButtonElement>},
  ref
) => {
  let startX = useRef<number>(),
    startY = useRef<number>();
  let uvLines = useRef<THREE.Group>(null);
  let mesh = useRef<THREE.Mesh>(null);
  let uvMapper = new UVMapper(null);
  let canvas2D = useRef<HTMLCanvasElement>(props.canvas2DObject);
  let sidePanel = props.sidePanel;
  let practiceFunction = props.practiceFunction;
  let goals = props.goals;
  let mouseDown = false;
  
  const textureProps = useTexture({
    map: uvTexture.default
  })

  if(canvas2D.current !=null) {
    canvasContext = canvas2D.current.getContext("2d");
    canvas2D.current.width = canvas2D.current.clientWidth;
    canvas2D.current.height = canvas2D.current.clientHeight;
}
  

  
  useEffect(() => {
    uvLines.current!.clear();
    canvas2D.current.width = canvas2D.current.clientWidth;
    canvas2D.current.height = canvas2D.current.clientHeight;
    wireframe = [];
    uvMapper = new UVMapper(props.geometry, false);
    goals = props.goals;
    practiceFunction = props.practiceFunction;

    mesh.current!.geometry= props.geometry;
   
    sidePanel.current!.setFunction(practiceFunction);
    sidePanel.current!.setGoal(goals.stretchGoal,goals.seamsGoal);
    initInteraction(uvMapper, mesh.current!, sidePanel.current!);
    createWireframe(mesh.current!, uvMapper, uvLines.current!);
    unwrap2(uvMapper, mesh.current!, sidePanel.current!);

    const onMouseDown = (event: MouseEvent) => {
      startX.current = event.pageX;
      startY.current = event.pageY;
      mouseDown = true;
    };

    const onMouseMove = (event: MouseEvent) => {
      if(startX.current == null || startY.current ==null || !mouseDown)return;
      diffX = Math.abs(event.pageX - startX.current);
      diffY = Math.abs(event.pageY - startY.current);  
    
    };

    const onMouseUp = (event:MouseEvent) => {
      if(startX.current == null || startY.current ==null)return;
      diffX = Math.abs(event.pageX - startX.current);
      diffY = Math.abs(event.pageY - startY.current);  
      mouseDown = false;
    };

    const resetEdges = (event: MouseEvent) =>
    {
      uvMapper.sewAll();
      unwrap2(uvMapper, mesh.current!, sidePanel.current!);
    }



    const onKeyDown = (event:KeyboardEvent) => {
      if (event.key === "Shift") shiftPressed = true;
      
    };

    const onKeyUp = (event:KeyboardEvent) => {
      if(mesh.current==null)return;
      if (event.key === "Shift") shiftPressed = false;
      else if (event.code === "KeyX") cutModel2(uvMapper, mesh.current, sidePanel.current!);
      else if (event.code === "KeyC") sewModel2(uvMapper, sidePanel.current!);
      else if (event.code === "KeyV") unwrap2(uvMapper, mesh.current, sidePanel.current!);
    };


    const onCanvasMouseDown = (event: MouseEvent) => {


      let smallestSide = Math.min(canvas2D.current.width,canvas2D.current.height)

      let orU = (canvas2D.current.width - smallestSide) / 2;
      let orV = (canvas2D.current.height - smallestSide) / 2;
      let size = Math.max(0, smallestSide - border * 2);
      let u = (event.clientX - canvas2D.current.getBoundingClientRect().left - orU)/size ;
      let v = 1 -( event.clientY - canvas2D.current.getBoundingClientRect().top - orV)/size;

      event.stopPropagation();
      if (!uvMapper.initialized) return;
      if (!shiftPressed) deselectEdges(uvMapper);
      computeUVLine(new Vector2(u,v), uvMapper);
      displayUV(uvMapper);
      displayMesh(uvMapper);
    };

    const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if(canvas2D.current !=null) {
            canvasContext = canvas2D.current.getContext("2d");
            canvas2D.current.width = canvas2D.current.clientWidth;
            canvas2D.current.height = canvas2D.current.clientHeight;
        }
            // Update the canvas dimensions. 
            displayUV(uvMapper);
        });
    });
    
    // Observer the image-div when it resizes.
    observer.observe(canvas2D.current);


    const onResize = () =>
    {
      canvas2D.current.width = canvas2D.current.clientWidth;
      canvas2D.current.height = canvas2D.current.clientHeight;
      displayUV(uvMapper);

    };

    sidePanel.current!.toolCutButton.current!.onclick = function(){cutModel2(uvMapper, mesh.current!, sidePanel.current!)};
    sidePanel.current!.toolSewButton.current!.onclick = function(){sewModel2(uvMapper, sidePanel.current!)};
    sidePanel.current!.toolUnfoldButton.current!.onclick = function(){unwrap2(uvMapper, mesh.current!, sidePanel.current!)};
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    window.addEventListener("resize", onResize);
    canvas2D.current.addEventListener('mousedown', onCanvasMouseDown);
    props.resetButton.current?.addEventListener("mousedown",resetEdges);
    return () => {
      sidePanel.current!.toolCutButton.current!.onclick = null;
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("resize", onResize);
      canvas2D.current.removeEventListener('mousedown', onCanvasMouseDown);
      props.resetButton.current?.removeEventListener("mousedown",resetEdges);
    };
  },[props.geometry]);

  useFrame((state, delta) => {
    pointUser.x = state.pointer.x;
    pointUser.y = -state.pointer.y;
  });

  const onPointerUp = useCallback((e:ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    if (!mesh.current || !uvLines.current  || !(diffX < delta && diffY < delta)) return;
    if (!uvMapper.initialized) return;
    if (!shiftPressed) deselectEdges(uvMapper);
    let intersectPoint = e.point;
    //testEdge(uvMapper, objectIntersect, uvLines);
    computeEdge(
      uvLines.current,
      mesh.current,
      intersectPoint,
      uvMapper,
      canvas2D.current
    );
    displayUV(uvMapper);
    displayMesh(uvMapper);
  },[props.geometry]);

  return (
    <>
      <group ref={uvLines}></group>
      <mesh
        scale={1}
        position={[0, 0, 0]}
        geometry={props.geometry}
        onPointerUp={onPointerUp}
        ref={mesh}
      >

        <meshStandardMaterial roughness={0.5} {...textureProps}/>
      </mesh>
    </>
  );
});



function computeEdge(uvLines:THREE.Group, mesh:THREE.Mesh, intersectPoint:THREE.Vector3, uvMapper:UVMapper, canvas2D:HTMLCanvasElement) {


  let minDistance = 999;
  let minEdgeIndex = 0;
  for (let i = 0; i < uvMapper.edgeMap.length; i++) {
    let line = new THREE.Line3().copy(uvMapper.edgeMap[i].line).applyMatrix4(mesh.matrixWorld);
    let closestPoint = new THREE.Vector3();
    let distance = line
      .closestPointToPoint(intersectPoint, true, closestPoint)
      .distanceTo(intersectPoint);
    //Closest ou distance
    if (distance < minDistance) {
      minDistance = distance;
      minEdgeIndex = i;
    }
  }
  if (minDistance > distanceThreshold) {
    if (!shiftPressed) deselectEdges(uvMapper);

    return;
  }


  if(AppSettings.DEBUG)console.log("MinIndexEdgeMap",minEdgeIndex);
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    for (
      let edgeIndex = 0;
      edgeIndex < uvMapper.faceMap[faceIndex].edges.length;
      edgeIndex++
    ) {
      if (
        uvMapper.faceMap[faceIndex].edges[edgeIndex].equals(
          uvMapper.edgeMap[minEdgeIndex]
        )
      ) {
        uvMapper.faceMap[faceIndex].edges[edgeIndex].selected = true;
        if(AppSettings.DEBUG)console.log("Face Selected",uvMapper.faceMap[faceIndex]);
        if(AppSettings.DEBUG)console.log("Edge Selected",uvMapper.faceMap[faceIndex].edges[edgeIndex]);
      }
    }
  }
}

function computeUVLine(uv:Vector2,uvMapper:UVMapper)
{
  let minDistance = 999;
  let minEdgeIndex = 0;
  for (let i = 0; i < uvMapper.edgeMap.length; i++) {
    let distance = uvMapper.edgeMap[i].getDistanceUV(uv);
    //Closest ou distance
    if (distance < minDistance) {
      minDistance = distance;
      minEdgeIndex = i;
    }
  }
  if (minDistance > 0.01) {
    if (!shiftPressed) deselectEdges(uvMapper);

    return;
  }

  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    for (
      let edgeIndex = 0;
      edgeIndex < uvMapper.faceMap[faceIndex].edges.length;
      edgeIndex++
    ) {
      if (
        uvMapper.faceMap[faceIndex].edges[edgeIndex].equals(
          uvMapper.edgeMap[minEdgeIndex]
        )
      ) {
        uvMapper.faceMap[faceIndex].edges[edgeIndex].selected = true;
        if(AppSettings.DEBUG)console.log("Face Selected",uvMapper.faceMap[faceIndex]);
        if(AppSettings.DEBUG)console.log("Edge Selected",uvMapper.faceMap[faceIndex].edges[edgeIndex]);
      }
    }
  }
}

async function unwrap2(uvMapper:UVMapper, mesh:THREE.Mesh, sidePanel:SidePanel) {
  uvMapper.chartingUVMap();
  uvMapper.updateUVGeometry(mesh);
  let stretchness = 0;
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    stretchness += uvMapper.faceMap[faceIndex].calculateStretch();
  }
  sidePanel.setGlobalStretchness(stretchness/ (1.8*uvMapper.faceMap.length));
  
  displayUV(uvMapper);
  displayMesh(uvMapper);
}

function initInteraction(uvMapper:UVMapper, mesh:THREE.Mesh, sidePanel : SidePanel)

{
  let edgeSeams = 0;
  for (let vertex = 0; vertex < uvMapper.uniqueVerticesMap.length; vertex++) {
    edgeSeams += uvMapper.uniqueVerticesMap[vertex].getSplitVertex() * 0.5;
  }
  let stretchness = 0;
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    stretchness += uvMapper.faceMap[faceIndex].calculateStretch();
  }
  sidePanel.setGlobalStretchness(stretchness/ (1.8*uvMapper.faceMap.length));
  sidePanel.setEdgeSeams(edgeSeams);
  
  displayUV(uvMapper);

}
function cutModel2(uvMapper:UVMapper, mesh:THREE.Mesh, sidePanel : SidePanel) {
  setSelectedToSeam(uvMapper, true);
  let edgeSeams = 0;
  for (let vertex = 0; vertex < uvMapper.uniqueVerticesMap.length; vertex++) {
    edgeSeams += uvMapper.uniqueVerticesMap[vertex].getSplitVertex() * 0.5;
    if (
      uvMapper.uniqueVerticesMap[vertex].getSplitVertex() > 1 &&
      uvMapper.uniqueVerticesMap[vertex].hasToSplitUV()
    ) {
      uvMapper.uniqueVerticesMap[vertex].splitUV();
    }
  }
  let stretchness = 0;
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    stretchness += uvMapper.faceMap[faceIndex].calculateStretch();
  }
  sidePanel.setGlobalStretchness(stretchness/ (1.8*uvMapper.faceMap.length));
  sidePanel.setEdgeSeams(edgeSeams);
  unselectAll(uvMapper)
  displayUV(uvMapper);
  displayMesh(uvMapper);
}

function unselectAll(uvMapper:UVMapper) {
  for (let edgeIndex = 0; edgeIndex < uvMapper.edgeMap.length; edgeIndex++) {
    uvMapper.edgeMap[edgeIndex].selected = false;
  }
}

function setSelectedToSeam(uvMapper:UVMapper, state:boolean) {
  for (let edgeIndex = 0; edgeIndex < uvMapper.edgeMap.length; edgeIndex++) {
    uvMapper.edgeMap[edgeIndex].selectedToStateSeam(state);
  }
}

function sewModel2(uvMapper:UVMapper, sidePanel : SidePanel) {
  setSelectedToSeam(uvMapper, false);
  let edgeSeams = 0;
  for (let vertex = 0; vertex < uvMapper.uniqueVerticesMap.length; vertex++) {
    edgeSeams += uvMapper.uniqueVerticesMap[vertex].getSplitVertex() * 0.5;
    uvMapper.uniqueVerticesMap[vertex].sewUV();
  }
  let stretchness = 0;
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    stretchness += uvMapper.faceMap[faceIndex].calculateStretch();
  }
  sidePanel.setGlobalStretchness(stretchness/ (1.8*uvMapper.faceMap.length));
  sidePanel.setEdgeSeams(edgeSeams);
  sidePanel.setEdgeSeams(edgeSeams);
  unselectAll(uvMapper)
  displayUV(uvMapper);
  displayMesh(uvMapper);
}

function createWireframe(mesh:THREE.Mesh, uvMapper:UVMapper, uvLines:THREE.Group) {
  let points;

  let lineMaterial;
  if(AppSettings.DEBUG && AppSettings.DEBUG_PARAMETERS.normals)testFaceNormals(mesh,uvMapper,uvLines);
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    for (
      let edgeIndex = 0;
      edgeIndex < uvMapper.faceMap[faceIndex].edges.length;
      edgeIndex++
    ) {
      points = uvMapper.faceMap[faceIndex].edges[edgeIndex].toPointsScale();
      let lineGeometry = new LineGeometry();
      lineGeometry.setPositions(points);

      if (DEBUG)
        addNormals(
          uvMapper.faceMap[faceIndex].edges[edgeIndex],
          mesh,
          uvLines
        );
      if (uvMapper.faceMap[faceIndex].edges[edgeIndex].edgeSeam)
        lineMaterial = new LineMaterial({ color: COLOR_SEAM.getHex() ,linewidth: 0.005});
      else if (uvMapper.faceMap[faceIndex].edges[edgeIndex].selected)
        lineMaterial = new LineMaterial({ color: COLOR_SELECTED.getHex() ,linewidth:0.005});
      else lineMaterial = new LineMaterial({ color: COLOR_EDGE.getHex() ,linewidth: 0.005});

      let object = new Line2(lineGeometry, lineMaterial);
      object.computeLineDistances();
      object.parent = mesh;
      uvLines.add(object);
      wireframe.push(object);
    }
  }
}

function deselectEdges(uvMapper:UVMapper) {
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    for (
      let edgeIndex = 0;
      edgeIndex < uvMapper.faceMap[faceIndex].edges.length;
      edgeIndex++
    ) {
      uvMapper.faceMap[faceIndex].edges[edgeIndex].selected = false;
    }
  }
}

function addNormals(edge:Edge, objectIntersect:THREE.Mesh, uvLines:THREE.Group) {
  let pointsNormal = edge.getPointsNormalVertex1();
  let lineGeometry = new THREE.BufferGeometry();
  lineGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(pointsNormal, 3)
  );

  let lineMaterial = new THREE.LineBasicMaterial({ color: COLOR_NORMAL });
  let object = new THREE.Line(lineGeometry, lineMaterial);

  object.parent = objectIntersect;
  uvLines.add(object);

  pointsNormal = edge.getPointsNormalVertex2();
  lineGeometry = new THREE.BufferGeometry();
  lineGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(pointsNormal, 3)
  );

  object = new THREE.Line(lineGeometry, lineMaterial);
  object.parent = objectIntersect;
  uvLines.add(object);
}

//#region DISPLAY
function displayMesh(uvMapper:UVMapper) {
  
  let wireframeIndex = 0;
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    for (
      let edgeIndex = 0;
      edgeIndex < uvMapper.faceMap[faceIndex].edges.length;
      edgeIndex++
    ) {
      if (uvMapper.faceMap[faceIndex].edges[edgeIndex].selected)
      // @ts-ignore
        wireframe[wireframeIndex].material.color.copy(COLOR_SELECTED);
      else if (uvMapper.faceMap[faceIndex].edges[edgeIndex].edgeSeam)
      // @ts-ignore
        wireframe[wireframeIndex].material.color.copy(COLOR_SEAM);
        // @ts-ignore
      else wireframe[wireframeIndex].material.color.copy(COLOR_EDGE);

      wireframeIndex++;
    }
  }
}


function displayUV(uvMapper:UVMapper) {
  if(canvasContext!=null)drawUVMap(canvasContext);
  let style;


  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {
    if(uvMapper.faceMap[faceIndex].faceDiagIndex != null)
    {
      uvMapper.faceMap[faceIndex].stretch = (uvMapper.faceMap[faceIndex].stretch + uvMapper.faceMap[uvMapper.faceMap[faceIndex].faceDiagIndex!].stretch) * 0.5;
      uvMapper.faceMap[uvMapper.faceMap[faceIndex].faceDiagIndex!].stretch = uvMapper.faceMap[faceIndex].stretch;
    }
  }
  for (let faceIndex = 0; faceIndex < uvMapper.faceMap.length; faceIndex++) {

    for (
      let edgeIndex = 0;
      edgeIndex < uvMapper.faceMap[faceIndex].edges.length;
      edgeIndex++
    ) {
      if (uvMapper.faceMap[faceIndex].edges[edgeIndex].selected) {
        style = { color: COLOR_SELECTED, width: 2 };
      } else if (uvMapper.faceMap[faceIndex].edges[edgeIndex].edgeSeam)
        style = { color: COLOR_SEAM };
      else style = { color: COLOR_EDGE, width: 0.5 };

      drawUVLine(
        {
          x: uvMapper.faceMap[faceIndex].edges[edgeIndex].getVertex1().uv.x,
          y: uvMapper.faceMap[faceIndex].edges[edgeIndex].getVertex1().uv.y,
          x1: uvMapper.faceMap[faceIndex].edges[edgeIndex].getVertex2().uv.x,
          y1: uvMapper.faceMap[faceIndex].edges[edgeIndex].getVertex2().uv.y,
        },
        style.color,
        style.width
      );
    }
 
    drawUVPolygon(
      {
        x: uvMapper.faceMap[faceIndex].vertex1.uv.x,
        y: uvMapper.faceMap[faceIndex].vertex1.uv.y,
        x1: uvMapper.faceMap[faceIndex].vertex2.uv.x,
        y1: uvMapper.faceMap[faceIndex].vertex2.uv.y,
        x2: uvMapper.faceMap[faceIndex].vertex3.uv.x,
        y2: uvMapper.faceMap[faceIndex].vertex3.uv.y,
      },
      uvMapper.faceMap[faceIndex].stretch
    );
  }
}

//#endregion
const border = 0;
const COLOR_BACKGROUND_UVMAP = "rgba(255, 255, 255, 0.5)";
let size: number, posX: number, posY: number, smallestSide;

function drawUVMap(canvasContext:CanvasRenderingContext2D) {
  canvasContext.clearRect(
    0,
    0,
    canvasContext.canvas.width,
    canvasContext.canvas.height
  );

  smallestSide = Math.min(
    canvasContext.canvas.width,
    canvasContext.canvas.height
  );
  size = Math.max(0, smallestSide - border * 2);

  posX = (canvasContext.canvas.width - smallestSide) / 2;
  posY = (canvasContext.canvas.height - smallestSide) / 2;
  canvasContext.beginPath();
  canvasContext.fillStyle = COLOR_BACKGROUND_UVMAP;
  canvasContext.rect(posX + border, posY + border, size, size);
  canvasContext.fill();
  canvasContext.globalAlpha = 0.3;
  canvasContext.drawImage(imageUVTexture,posX + border, posY + border, size, size);
  
}

function drawUVLine(info:{x:number,y:number,x1:number,y1:number}, color = new THREE.Color("0x000000"), width = 1) {
  if(canvasContext == null)return;
  let { x, y, x1, y1 } = info;

  x = x * size + posX + border;
  y = (1 - y) * size + posY + border;
  x1 = x1 * size + posX + border;
  y1 = (1 - y1) * size + posY + border;
  canvasContext.globalAlpha = 1;
  canvasContext.beginPath();
  canvasContext.moveTo(x, y);
  canvasContext.lineTo(x1, y1);
  canvasContext.strokeStyle = color.getStyle();
  canvasContext.lineWidth = width;
  canvasContext.closePath();
  canvasContext.stroke();
}

const stretchCoeff = 1.05
function drawUVPolygon(info:{x:number,y:number,x1:number,y1:number,x2:number,y2:number}, stretchValue = 0) {
  if(canvasContext == null)return;
  canvasContext.globalAlpha = 0.8;
  let { x, y, x1, y1, x2, y2 } = info;

  x = x * size + posX + border;
  y = (1 - y) * size + posY + border;
  x1 = x1 * size + posX + border;
  y1 = (1 - y1) * size + posY + border;
  x2 = x2 * size + posX + border;
  y2 = (1 - y2) * size + posY + border;
  
  
  canvasContext.fillStyle = new THREE.Color(1, 1 - (stretchValue/90)*stretchCoeff, 1 - (stretchValue/90)*stretchCoeff).getStyle();

  canvasContext.beginPath();
  canvasContext.moveTo(x, y);
  canvasContext.lineTo(x1, y1);
  canvasContext.lineTo(x2, y2);
  canvasContext.closePath();

  canvasContext.fill();
}

//#region DEBUG
function testEdge(uvMapper:UVMapper, objectIntersect:THREE.Mesh, uvLines:THREE.Group) {
  let color1 = new THREE.Color(0x00ffff);

  for (let v = 0; v < uvMapper.edgeMap.length; v++) {
    let points = uvMapper.edgeMap[v].toPoints();
    let lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(points, 3)
    );

    let lineMaterial = new THREE.LineBasicMaterial({
      color: color1,
      linewidth: 20,
    });
    let object = new THREE.Line(lineGeometry, lineMaterial);
    object.parent = objectIntersect;
    uvLines.add(object);
  }
}

//#region DEBUG
function testNormals(mesh:THREE.Mesh, uvMapper:UVMapper, uvLines:THREE.Group) {
  let color1 = new THREE.Color(0x0000ff);

  for (let v = 0; v < uvMapper.vertexMap.length; v++) {
    let points = uvMapper.vertexMap[v].getPositionPoints() ;
    points = points.concat(uvMapper.vertexMap[v].getNormalVectorPoints());
    let lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(points, 3)
    );

    let lineMaterial = new THREE.LineBasicMaterial({
      color: color1,
      linewidth: 20,
    });
    let object = new THREE.Line(lineGeometry, lineMaterial);
    object.parent = mesh;
    uvLines.add(object);
  }
}

//#region DEBUG
function testFaceNormals(mesh:THREE.Mesh, uvMapper:UVMapper, uvLines:THREE.Group) {
  let color1 = new THREE.Color(0x0000ff);

  for (let v = 0; v < uvMapper.faceMap.length; v++) {
    let points = uvMapper.faceMap[v].getPositionCenter().toArray() as number[] ;
    points = points.concat(new THREE.Vector3().addVectors(uvMapper.faceMap[v].getPositionCenter(),uvMapper.faceMap[v].faceNormal).toArray());
    let lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(points, 3)
    );

    let lineMaterial = new THREE.LineBasicMaterial({
      color: color1,
      linewidth: 20,
    });
    let object = new THREE.Line(lineGeometry, lineMaterial);
    object.parent = mesh;
    uvLines.add(object);
  }
}


function displayEdge(edge:Edge, mesh:THREE.Mesh, uvLines:THREE.Group, color: Color) {
  let points = edge.toPoints();
  let lineGeometry = new THREE.BufferGeometry();
  lineGeometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(points, 3)
  );

  let lineMaterial = new THREE.LineBasicMaterial({ color: color });
  let object = new THREE.Line(lineGeometry, lineMaterial);

  object.parent = mesh;
  uvLines.add(object);
}
//#endregion
