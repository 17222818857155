import { Vector3, Vector2, Line3} from "three";
import { AppSettings } from "../AppSettings";
import * as Utils from './Utils';

declare global
{
  interface Array<T>
  {
    last():T;
  }
}
Array.prototype.last = function () {
  return this[this.length - 1];
};

function shiftedVector3(vector:Vector3, offset=1):Vector3
{
  if(offset%3 == 1)
  {
    return new Vector3(vector.z,vector.x, vector.y);
  }
  else if(offset%3 == 2)
  {
    return new Vector3(vector.y,vector.z, vector.x);
  }
  else 
  {
    return vector;
  }
}



function angleTriangle(x1:number,x2:number,x3:number,y1:number,y2:number,y3:number,z1:number,z2:number,z3:number):number
{
    let num = (x2-x1)*(x3-x1)+(y2-y1)*(y3-y1)+(z2-z1)*(z3-z1) ;
   
    let den = Math.sqrt(Math.pow((x2-x1),2)+
                Math.pow((y2-y1),2)+Math.pow((z2-z1),2))*
                Math.sqrt(Math.pow((x3-x1),2)+
                Math.pow((y3-y1),2)+Math.pow((z3-z1),2)) ;
   
    let angle = Math.acos(num / den)*(180.0/3.141592653589793238463) ;
   
    return angle ;
}

export class UniqueVertex {
  threshold:number = 0.999999;
  split:number = 0;
  index:number;
  listVertices:Array<{v :Vertex, e : Array<Edge>, f:Face, id:number}>;
  position:Vector3;
  quadVertices:Array<{v :Vertex, e : Array<Edge>, f:Face, id:Set<number>}>;
  spatialEdges:Array<{v :Vertex, e : Array<Edge>}>;
  lineReference?:Vector3;

  constructor(index:number, position:Vector3) {
    this.index = index;
    
    this.listVertices = [];
    this.position = position;
    this.quadVertices = [];
    this.spatialEdges = [];
  }

  addVertexEdge(vertex:Vertex, edge:Edge, face:Face) {
   

    if (this.listVertices.length == 0) {
      this.lineReference = edge.deltaLine;
      if (!edge.vertex1.equals(vertex)) {
        this.lineReference.negate();
      }
    }
    for (let ve of this.listVertices) {
      if (ve.f.indexFace == face.indexFace) {
        ve.e.push(edge);
        return;
      }
    }

    this.listVertices.push({
      v: vertex,
      e: [edge],
      f: face,
      id: this.listVertices.length,
    });

  }

  equalsPosition(vertex:Vertex) {
    return (
      Math.abs(this.position.x - vertex.position.x) < 1 - this.threshold &&
      Math.abs(this.position.y - vertex.position.y) < 1 - this.threshold &&
      Math.abs(this.position.z - vertex.position.z) < 1 - this.threshold
    );
  }

  getSplitVertex() {
    this.split = 0;
    for (let ve of this.quadVertices) {
      if (ve.e[0].edgeSeam) this.split++;
    }

    return this.split;
  }

  computeEdges() {
    
    for (let ve of this.listVertices) {
      if (ve.e.length > 1) {
        for (let i = 0; i < ve.e.length; i++) {
          this.addQuadEdges(ve.v, ve.e[i], ve.f, ve.id);
        }
      } else {
        this.addQuadEdges(ve.v, ve.e[0], ve.f, ve.id);
      }
    }

    this.quadEdgesRearrange();
  }

  //CHECK IF ID ARE GOOD
  addQuadEdges(vertex:Vertex, edge:Edge, face:Face, id:number) {
    for (let ve of this.quadVertices) {
      if (ve.f.indexFace == face.indexFace) {
        ve.e.push(edge);
        ve.id.add(id);
        return;
      }

      if (face.faceDiagIndex == null) continue;
      if (ve.f.indexFace == face.faceDiagIndex) {
        ve.e.push(edge);
        ve.id.add(id);
        return;
      }
    }

    this.quadVertices.push({
      v: vertex,
      e: [edge],
      f: face,
      id: new Set([id]),
    });
  }

  //Unstoppable while
  quadEdgesRearrange() {
    let newQuadVertices = [];
    newQuadVertices.push(this.quadVertices.splice(0, 1)[0]);
    let compt = 0;
    while (this.quadVertices.length > 0 && compt<1000) {
      compt++;
      for (let i = 0; i < this.quadVertices.length; i++) {
        if(this.quadVertices[i].e[1] == undefined)
        {
          console.error(this.listVertices);
          let vert = this.quadVertices.splice(i, 1)[0];
          newQuadVertices.push(vert);
        }
        if (this.quadVertices[i].e[0].equals(newQuadVertices.last().e[1])) {
          let vert = this.quadVertices.splice(i, 1)[0];

          newQuadVertices.push(vert);
        } else if (
          this.quadVertices[i].e[1].equals(newQuadVertices.last().e[1])
        ) {
          let vert = this.quadVertices.splice(i, 1)[0];
          vert.e.reverse();
          newQuadVertices.push(vert);
        }
      }
    }
    if(compt > 999)console.warn("Unfinished",this.quadVertices);

    this.quadVertices = newQuadVertices;
  }

  splitUV() {
    let angleEdges = 0;
    let indexFirstSeam = this.getFirstEdgeSeamIndex();
    if (indexFirstSeam == null) {
      if(AppSettings.DEBUG)console.log("ERROR : NO UVs TO SPLIT !");
      return;
    }
    let len = this.quadVertices.length;
    let verticesToSplit = [];
    let uvNormal = this.quadVertices[indexFirstSeam].e[0].uv1;
    let uvCenter = this.quadVertices[indexFirstSeam].e[0].vertex1.uv;
    if (
      !this.quadVertices[indexFirstSeam].e[0].vertex1.equals(
        this.quadVertices[0].v
      )
    ) {
      uvNormal.negate();
      uvCenter = this.quadVertices[indexFirstSeam].e[0].vertex2.uv;
    }
    for (let i = indexFirstSeam; i < indexFirstSeam + len; i++) {
      angleEdges += Math.PI / (0.5 * len);
      verticesToSplit.push(this.quadVertices[i % len]);
      if (this.quadVertices[i % len].e[1].edgeSeam) {
        uvNormal.rotateAround(uvCenter, angleEdges);
        for (let vertex of verticesToSplit) {
          for (const id of vertex.id) {
            this.listVertices[id].v.uv.lerp(uvNormal, 0.01);
          }
        }

        uvNormal = this.quadVertices[i % len].e[1].uv1.normalize();
        if (
          !this.quadVertices[i % len].e[1].vertex1.equals(
            this.quadVertices[0].v
          )
        ) {
          uvNormal.negate();
          uvCenter = this.quadVertices[i % len].e[1].vertex2.uv;
        }
        angleEdges = 0;
        verticesToSplit = [];
      }
    }
  }

  hasToSplitUV() {
    for (let i = 0; i < this.quadVertices.length; i++) {
      if (this.quadVertices[i].e[1].edgeSeam) {
        let merge = new Set([
          ...this.quadVertices[i].id,
          ...this.quadVertices[(i + 1) % this.quadVertices.length].id,
        ]);
        let index = [...this.quadVertices[i].id][0];
        let v = this.listVertices[index].v;
        for (const id of merge) {
          if (id != index) {
            if (v.equalsUV(this.listVertices[id].v)) return true;
          }
        }
      }
    }

    return false;
  }
  sewUV() {
    if (this.getSplitVertex() < 2) {
      let uvCenter = new Vector2(0, 0);
      for (let ve of this.listVertices) {
        uvCenter.add(ve.v.uv);
      }
      uvCenter.divideScalar(this.listVertices.length);

      for (let ve of this.listVertices) {
        ve.v.uv.copy(uvCenter);
      }
    } else {
      for (let i = 0; i < this.quadVertices.length; i++) {
        if (!this.quadVertices[i].e[1].edgeSeam) {
          let uvCenter = new Vector2(0, 0);
          let shouldMerge = false;
          let merge = new Set([
            ...this.quadVertices[i].id,
            ...this.quadVertices[(i + 1) % this.quadVertices.length].id,
          ]);
          let index = [...this.quadVertices[i].id][0];
          let v = this.listVertices[index].v;
          for (const id of merge) {
            if (id != index) {
              uvCenter.add(this.listVertices[id].v.uv);
              if(AppSettings.DEBUG)console.log(v);
              if(AppSettings.DEBUG)console.log(this.listVertices[id].v);
              if (!v.equalsUV(this.listVertices[id].v)) {
                shouldMerge = true;
              }
            }
          }
          if(AppSettings.DEBUG)console.log(shouldMerge);
          if (shouldMerge) {
            uvCenter.divideScalar(merge.size - 1);
            for (const id of merge) {
              this.listVertices[id].v.uv.copy(uvCenter);
            }
          }
        }
      }
    }
  }

  sewAll()
  {
    for (let i = 0; i < this.quadVertices.length; i++) {
      for( let e= 0; e < this.quadVertices[i].e.length ; e++)
      {
        this.quadVertices[i].e[e].setEdgeSeam(false);
      }
    }
  }
  getFirstEdgeSeamIndex() {
    for (let i = 0; i < this.quadVertices.length; i++) {
      if (this.quadVertices[i].e[0].edgeSeam) return i;
    }
    return null;
  }

  getEdgeLoop(edge:Edge) {
    if(this.listVertices.length != 6)
    {
      return null;
    }

    let pos = -1;
    for(let i = 0; i < this.quadVertices.length; i++)
    {
      pos = this.quadVertices[i].e.indexOf(edge);
      if(pos != -1)
      {
        let index = 0;
        for(let j = pos + 1; j < this.quadVertices.length; j++)
        {
          if(!this.quadVertices[j%this.quadVertices.length].e[0].diag) index++;
          if(index==2) return this.quadVertices[j%this.quadVertices.length].e[0];
        }
        console.error("EdgeLoop");
      }
    }
    if(pos == -1) return null;

  }
}

export class Vertex {
  threshold:number = 0.999999;
  position:Vector3;
  normal:Vector3;
  uv:Vector2;
  isSplit:boolean;
  vertexCount:number;
  facesIndex:Array<number>;
  idChart:number;
  
  constructor(position:Vector3, uv:Vector2, normal:Vector3, vertexCount:number, facesIndex:Array<number> = [], idChart:number  = 0) {
    this.position = position;
    this.uv = uv;
    this.normal = normal;
    this.isSplit = false;
    this.vertexCount = vertexCount;
    this.facesIndex = facesIndex;
    this.idChart = idChart;
  }

  getPositionPoints() {
    return [this.position.x, this.position.y, this.position.z];
  }

  setCoordinateUV(index:number, value:number)
  {
    
    if(index==0)this.uv.x = value;
    else this.uv.y= value;
  }

  getCoordinatePos(index:number)
  {

    if(index==0)return this.position.x;
    else if(index == 1)return this.position.y;
    else return this.position.z;
  }

  getUVPoints() {
    return [this.uv.x, this.uv.y];
  }
  getNormalPoints() {
    return [this.normal.x, this.normal.y, this.normal.z];
  }

  getNormalVectorPoints() {
    return [this.position.x + this.normal.x, this.position.y + this.normal.y, this.position.z + this.normal.z];
  }

  equalsPosition(vertex:Vertex) {
    return (
      Math.abs(this.position.x - vertex.position.x) < 1 - this.threshold &&
      Math.abs(this.position.y - vertex.position.y) < 1 - this.threshold &&
      Math.abs(this.position.z - vertex.position.z) < 1 - this.threshold
    );
  }

  equalsUV(vertex:Vertex) {
    return (
      Math.abs(this.uv.x - vertex.uv.x) < 1 - this.threshold &&
      Math.abs(this.uv.y - vertex.uv.y) < 1 - this.threshold
    );
  }

  equals(vertex:Vertex) {
    return this.equalsPosition(vertex) && this.equalsUV(vertex);
  }

  addFace(faceIndex:number) {
    this.facesIndex.push(faceIndex);
  }
  randomizeUV() {
    this.uv.addScalar(Math.random() * 0.1);
  }
  clone() {
    let uvC = new Vector2(this.uv.x, this.uv.y);
    return new Vertex(
      this.position,
      uvC,
      this.normal,
      this.vertexCount,
      this.facesIndex,
      this.idChart
    );
  }
}

export class Edge {
  threshold:number = 0.999999;
  line:Line3;
  vertex1:Vertex;
  vertex2:Vertex;
  edgeSeam: boolean;
  selected:boolean;
  diag:boolean;
  oppositeFaceIndex?:number;
  deltaLine:Vector3;
  faceNormal:Vector3;
  uv1:Vector2;
  uv2:Vector3;
  normalVector1:Vector3;
  normalVector2:Vector3;

  constructor(vertexStart:Vertex, vertexEnd:Vertex, faceNormal:Vector3) {
    this.line = new Line3(vertexStart.position, vertexEnd.position);
    this.vertex1 = vertexStart;
    this.vertex2 = vertexEnd;
    this.edgeSeam = false;
    this.selected = false;
    this.diag = false;

    this.deltaLine = new Vector3();
    this.line.delta(this.deltaLine);
    this.deltaLine.normalize();
    this.faceNormal = faceNormal;

    this.uv1 = new Vector2(0, 0);
    this.uv1.subVectors(this.vertex2.uv, this.vertex1.uv);

    this.uv2 = new Vector3(0, 0, 0);
    this.uv2.x = this.vertex2.uv.x -  this.vertex1.uv.x;
    this.uv2.y = this.vertex2.uv.y -  this.vertex1.uv.y;

    this.normalVector1 = new Vector3();
    this.normalVector1.addVectors(this.vertex1.position, this.vertex1.normal);

    this.normalVector2 = new Vector3();
    this.normalVector2.addVectors(this.vertex2.position, this.vertex2.normal);
  }

  clone() {
    let clone = new Edge(
      this.vertex1.clone(),
      this.vertex2.clone(),
      this.faceNormal
    );
    clone.setEdgeSeam(this.edgeSeam);
    clone.setOppositeFaceIndex(this.oppositeFaceIndex);
    return clone;
  }

  selectedToStateSeam(booleanState:boolean) {
    if (this.selected) this.setEdgeSeam(booleanState);
  }
  setEdgeSeam(booleanState:boolean) {
    this.edgeSeam = booleanState;
    this.vertex1.isSplit = booleanState;
    this.vertex2.isSplit = booleanState;
  }

  setOppositeFaceIndex(faceIndex?:number) {
    this.oppositeFaceIndex = faceIndex;
  }

  toPoints() {
    return this.line.start.toArray().concat(this.line.end.toArray());
  }
  toPointsScale() {
    return new Vector3().add(this.line.start).lerp(this.line.end,0.005).toArray().concat(new Vector3().add(this.line.end).lerp(this.line.start,0.005).toArray());
  }
  toPointsVector() {
    return [this.line.start,this.line.end];
  }

  getDistanceUV(uv:Vector2)
  {
    let length = this.vertex1.uv.distanceToSquared(this.vertex2.uv);
    let t = Math.max(0, Math.min(1, new Vector2().subVectors(uv, this.vertex1.uv).dot(new Vector2().subVectors(this.vertex2.uv, this.vertex1.uv)) / length));
    let p = new Vector2().addVectors(this.vertex1.uv, new Vector2().subVectors(this.vertex2.uv,uv).multiplyScalar(t));
    return uv.distanceToSquared(p);
  }

  getVertex1() {
    if (this.edgeSeam) {
      return this.vertex1;
      //return this.vertex1Copy;
    } else {
      return this.vertex1;
    }
  }

  getVertex2() {
    if (this.edgeSeam) {
      return this.vertex2;
      //return this.vertex2Copy;
    } else {
      return this.vertex2;
    }
  }

  getPointsNormalVertex1() {
    return this.vertex1.position.toArray().concat(this.normalVector1.toArray());
  }

  getPointsNormalVertex2() {
    return this.vertex2.position.toArray().concat(this.normalVector2.toArray());
  }

  equals(edge:Edge) {
    if (edge == null) return false;
    if(this.line.start.distanceToSquared(edge.line.start) < 1 - this.threshold && this.line.end.distanceToSquared(edge.line.end) < 1 - this.threshold)return true;
    if(this.line.start.distanceToSquared(edge.line.end) < 1 - this.threshold && this.line.end.distanceToSquared(edge.line.start) < 1 - this.threshold)return true;
    return false;
  }

  
  displaceUV(faceCentroid:Vector2) {
    this.getVertex1().uv.lerp(faceCentroid, 0.1);
    this.getVertex2().uv.lerp(faceCentroid, 0.1);
    /*this.getVertex2().uv = this.getVertex2().uv.lerpVectors(
      this.getVertex2().uv,
      faceCentroid,
      0.1
    );*/
  }
  equalsUV(edge:Edge) {
    return (
      Math.abs(this.vertex1.uv.x - edge.vertex2.uv.x) < 1 - this.threshold &&
      Math.abs(this.vertex1.uv.y - edge.vertex2.uv.y) < 1 - this.threshold
    );
  }

  copyUV(edge:Edge) {
    if (
      this.line.start.distanceToSquared(edge.line.start) <
      1 - this.threshold
    ) {
      this.vertex1.uv.copy(edge.vertex1.uv);
      this.vertex2.uv.copy(edge.vertex2.uv);
    } else {
      this.vertex1.uv.copy(edge.vertex2.uv);
      this.vertex2.uv.copy(edge.vertex1.uv);
    }
  }

  lerpUV(edge:Edge, factor = 0.5) {
    if (
      this.line.start.distanceToSquared(edge.line.start) <
      1 - this.threshold
    ) {
      this.vertex1.uv.lerp(edge.vertex1.uv, factor);
      this.vertex2.uv.lerp(edge.vertex2.uv, factor);
    } else {
      this.vertex1.uv.lerp(edge.vertex2.uv, factor);
      this.vertex2.uv.lerp(edge.vertex1.uv, factor);
    }
  }

  getAngle(edge:Edge) {

    if(this.vertex1.equalsPosition(edge.vertex1) || this.vertex1.equalsPosition(edge.vertex2) )
    {
      if(AppSettings.DEBUG)console.log(this.line.start.distanceToSquared(edge.line.start) <
      1 - this.threshold);
      if (
        this.line.start.distanceToSquared(edge.line.start) <
        1 - this.threshold
      ) {
        return this.deltaLine.angleTo(edge.deltaLine);
      } else {
        return this.deltaLine.angleTo(edge.deltaLine.clone().negate());
      }    
    }
    
    return this.deltaLine.angleTo(edge.deltaLine);
  }

  getAngleUV(edge:Edge) {
    if(this.vertex1.equalsPosition(edge.vertex1) || this.vertex1.equalsPosition(edge.vertex2) )
    {
      if (
        this.line.start.distanceToSquared(edge.line.start) <
        1 - this.threshold
      ) {
        return this.uv2.angleTo(edge.uv2);
      } else {
        return this.uv2.angleTo(edge.uv2.clone().negate());
      }
    }
    return this.uv2.angleTo(edge.uv2);
  }
  
}

export class Face {
  coplanarThreshold:number = 0.001;
  commonEdgeDone:boolean = false;
  diagEdge?:Edge;
  faceDiagIndex?:number;
  vertex1:Vertex;
  vertex2:Vertex;
  vertex3:Vertex;
  indexFace:number;
  faceNormal:Vector3;
  uvCentroid:Vector2;
  edge1:Edge;
  edge2:Edge;
  edge3:Edge;
  edges:Array<Edge>;
  stretch:number = 0;

  constructor(vertex1:Vertex, vertex2:Vertex, vertex3:Vertex, indexFace:number) {
    this.vertex1 = vertex1.clone();
    this.vertex2 = vertex2.clone();
    this.vertex3 = vertex3.clone();

    this.indexFace = indexFace;
    if (
      this.vertex2.normal.equals(this.vertex1.normal) &&
      this.vertex2.normal.equals(this.vertex3.normal)
    )
    this.faceNormal = new Vector3().copy(this.vertex2.normal);
    else {
      this.faceNormal = new Vector3()
        .crossVectors(
          new Vector3().subVectors(this.vertex3.position, this.vertex2.position),
          new Vector3().subVectors(this.vertex1.position, this.vertex2.position)
        )
        .normalize();
    }

    this.uvCentroid = new Vector2(
      0.333 * (this.vertex1.uv.x + this.vertex2.uv.x + this.vertex3.uv.x),
      0.333 * (this.vertex1.uv.y + this.vertex2.uv.y + this.vertex3.uv.y)
    );

    this.edge1 = new Edge(this.vertex1, this.vertex2, this.faceNormal);
    this.edge2 = new Edge(this.vertex2, this.vertex3, this.faceNormal);
    this.edge3 = new Edge(this.vertex3, this.vertex1, this.faceNormal);

    this.edges = [this.edge1, this.edge2, this.edge3];
  }

  isCoplanar(face2:Face) {
    return this.faceNormal.angleTo(face2.faceNormal) < this.coplanarThreshold;
  }
  getPositionCenter()
  {
    return new Vector3().add(this.vertex1.position).add(this.vertex2.position).add(this.vertex3.position).multiplyScalar(1/3);
  }
  calculateArea()
  {
    this.faceNormal= new Vector3()
    .crossVectors(
      new Vector3().subVectors(this.vertex3.position, this.vertex2.position),
      new Vector3().subVectors(this.vertex1.position, this.vertex2.position)
    )
    return (this.faceNormal.length()*0.5);

  }

  uvScale(scale:number)
  { 
    this.vertex1.uv.multiplyScalar(scale);
    this.vertex2.uv.multiplyScalar(scale);
    this.vertex3.uv.multiplyScalar(scale);
  }

  uvTranslate(trans:Vector2)
  {
    this.vertex1.uv.add(trans);
    this.vertex2.uv.add(trans);
    this.vertex3.uv.add(trans);
  }

  calculateStretch() {
    let angleEdge = 0;
    let angleUV = 0;
    this.stretch = 0;

    angleEdge = (Utils.angleTriVector3(this.vertex1.position,this.vertex2.position,this.vertex3.position));
    angleUV = Utils.angleTriVector2(this.vertex1.uv,this.vertex2.uv,this.vertex3.uv);
    this.stretch += Math.abs(angleEdge - angleUV);

    angleEdge =(Utils.angleTriVector3(this.vertex2.position,this.vertex3.position,this.vertex1.position));
    angleUV = (Utils.angleTriVector2(this.vertex2.uv,this.vertex3.uv,this.vertex1.uv));
    this.stretch += Math.abs(angleEdge - angleUV);

    angleEdge =(Utils.angleTriVector3(this.vertex3.position,this.vertex2.position,this.vertex1.position));
    angleUV =(Utils.angleTriVector2(this.vertex3.uv,this.vertex2.uv,this.vertex1.uv));
    this.stretch += Math.abs(angleEdge - angleUV);
   
    return this.stretch;
  }

  getTrianglesAngle():{v1:number, v2:number, v3:number}
  {
    return {
      v1:Utils.angleTriVector3(this.vertex1.position,this.vertex2.position,this.vertex3.position) * (Math.PI/180),
      v2:Utils.angleTriVector3(this.vertex2.position,this.vertex3.position,this.vertex1.position) * (Math.PI/180),
      v3:Utils.angleTriVector3(this.vertex3.position,this.vertex2.position,this.vertex1.position)* (Math.PI/180)

    };
  }

  disableCommonEdgeTri(face2:Face) {
    for (let i = 0; i < this.edges.length; i++) {
      for (let j = 0; j < face2.edges.length; j++) {
        if (this.edges[i].equals(face2.edges[j])) {
          this.diagEdge = this.edges.splice(i, 1)[0];
          this.commonEdgeDone = true;
          this.diagEdge.diag = true;
          this.faceDiagIndex = face2.indexFace;
          face2.diagEdge = face2.edges.splice(j, 1)[0];
          face2.diagEdge.diag = true;
          face2.commonEdgeDone = true;
          face2.faceDiagIndex = this.indexFace;

          return;
        }
      }
    }
  }

  getVertices() {
    return [this.vertex1, this.vertex2, this.vertex3];
  }
  getVertexFromVertexCount(vertexCount:number) {
    if (this.vertex1.vertexCount == vertexCount) return this.vertex1;
    else if (this.vertex2.vertexCount == vertexCount) return this.vertex2;
    else return this.vertex3;
  }

  //On utilise l'input de getCommonEgeIndex
  setupEdgeSeams(edgeIndex:number, edge2:Edge) {
    if (!this.edges[edgeIndex].equalsUV(edge2)) {
      this.edges[edgeIndex].setEdgeSeam(true);
      edge2.setEdgeSeam(true);
    }
  }

  setOppositeFaceIndex(edgeIndex:number, faceIndex:number) {
    this.edges[edgeIndex].setOppositeFaceIndex(faceIndex);
  }

  cutEdge(edgeIndex:number, edge2:Edge) {
    this.edges[edgeIndex].setEdgeSeam(true);
    edge2.setEdgeSeam(true);
  }

  sewEdge(edgeIndex:number, edge2:Edge) {
    this.edges[edgeIndex].setEdgeSeam(false);
    edge2.lerpUV(this.edges[edgeIndex]);
    this.edges[edgeIndex].copyUV(edge2);
    edge2.setEdgeSeam(false);
  }

  getCommonVertex(vertex:Vertex, checkSeam = true) {
    if (vertex.equals(this.vertex1)) {
      return this.vertex1;
    } else if (vertex.equals(this.vertex2)) {
      return this.vertex2;
    } else if (vertex.equals(this.vertex3)) {
      return this.vertex3;
    }

    return null;
  }

  getCommonEdgeIndex(face2: Face) {
    for (let i = 0; i < this.edges.length; i++) {
      for (let j = 0; j < face2.edges.length; j++) {
        if (this.edges[i].equals(face2.edges[j])) {
          return { i, j };
        
        }
      }
    }
    return null;
  }

  getCommonEdge(edge:Edge) {
    for (let i = 0; i < this.edges.length; i++) {
      if (this.edges[i].equals(edge)) {
        return this.edges[i];
      }
    }
    return null;
  }

  getCommonVertexIndex(face2:Face) {
    for (let i = 0; i < this.edges.length; i++) {
      for (let j = 0; j < face2.edges.length; j++) {
        if (
          this.edges[i].vertex1.equalsPosition(face2.edges[j].vertex1) ||
          this.edges[i].vertex1.equalsPosition(face2.edges[j].vertex2)
        ) {
          return { i, j };
        }
      }
    }
    return null;
  }

  setEdgeSeam(edgeIndex:number, state:boolean) {
    this.edges[edgeIndex].setEdgeSeam(state);
  }

  getNeighborsIndex() {
    let neighbors = [];
    for (let i = 0; i < this.edges.length; i++) {
      if (!this.edges[i].edgeSeam && this.edges[i].oppositeFaceIndex != null)
        neighbors.push(this.edges[i].oppositeFaceIndex);
    }

    if (this.diagEdge != null && this.diagEdge.oppositeFaceIndex != null) {
      neighbors.push(this.diagEdge.oppositeFaceIndex);
    }
    return neighbors;
  }
  getEdgeSeams() {
    let array = [];
    for (let i = 0; i < this.edges.length; i++) {
      if (this.edges[i].edgeSeam) array.push(this.edges[i]);
    }

    return array;
  }
}
