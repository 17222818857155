import { useState } from "react";


const FORM_ENDPOINT = "/contact-processor.php";

export function ContactForm() {
   
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
            setSubmitted(true);
        }, 100);
    };

    if (submitted) {
        return (
            <p style ={{paddingLeft:"2em"}}>Thank you for your feedback!</p>
        );
    }

    return (
        <form
        action={FORM_ENDPOINT}
        onSubmit={handleSubmit}
        method="POST" 
      >

        <h2>Contact</h2>
        <div >
          <input
            type="text"
            placeholder="Your name"
            name="name"
            required
          />
        </div>
        <div >
          <input
            type="email"
            placeholder="Email"
            name="email"
            required
          />
        </div>
        <div className="Message">
          <textarea
            placeholder="Your message"
            name="message"
            required
          />
        </div>
        <div>
          <button
        
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
      );

    }