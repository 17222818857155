import { Vector2, Vector3 } from "three";
import { Face, Vertex } from "./Geo";

export function Shift3(object:any){

    let tmp = object.v1;
    object.v1 = object.v3;
    object.v3 = object.v2;
    object.v2  = tmp;

}

export function Swap(object:any){
    let swap = object.v1;
    object.v2 = object.v1;
    object.v1 = swap;
}

export function angleTriVector3(v1:Vector3,v2:Vector3, v3:Vector3)
{
  let num = (v2.x-v1.x)*(v3.x-v1.x)+(v2.y-v1.y)*(v3.y-v1.y)+(v2.z-v1.z)*(v3.z-v1.z) ;
   
  let den = Math.sqrt(Math.pow((v2.x-v1.x),2)+
              Math.pow((v2.y-v1.y),2)+Math.pow((v2.z-v1.z),2))*
              Math.sqrt(Math.pow((v3.x-v1.x),2)+
              Math.pow((v3.y-v1.y),2)+Math.pow((v3.z-v1.z),2)) ;
 
  let angle = Math.acos(num / den)*(180.0/3.141592653589793238463) ;
 
  return angle ;
}

export function angleTriVector2(v1:Vector2,v2:Vector2, v3:Vector2)
{
  let num = (v2.x-v1.x)*(v3.x-v1.x)+(v2.y-v1.y)*(v3.y-v1.y) ;
   
  let den = Math.sqrt(Math.pow((v2.x-v1.x),2)+
              Math.pow((v2.y-v1.y),2)+Math.pow(0,2))*
              Math.sqrt(Math.pow((v3.x-v1.x),2)+
              Math.pow((v3.y-v1.y),2)+Math.pow(0,2)) ;
 
  let angle = Math.acos(num / den)*(180.0/3.141592653589793238463) ;
 
  return angle ;
}


export function p_rectangle_area(p1:Vector2, dir:Vector2, p2:Vector2, p3:Vector2, p4:Vector2)
{
  /* given 4 points on the rectangle edges and the direction of on edge,
   * compute the area of the rectangle */

  let orthodir = new Vector2(dir.y,-dir.x),
   corner1 = new Vector2(), corner2 = new Vector2(), corner3 = new Vector2();


  if (!p_intersect_line_2d_dir(p1, dir, p2, orthodir, corner1)) {
    return 1e10;
  }

  if (!p_intersect_line_2d_dir(p1, dir, p4, orthodir, corner2)) {
    return 1e10;
  }

  if (!p_intersect_line_2d_dir(p3, dir, p4, orthodir, corner3)) {
    return 1e10;
  }

  return corner1.distanceTo(corner2) * corner2.distanceTo(corner3);
}

function p_intersect_line_2d_dir(v1:Vector2,
    dir1:Vector2,
    v2:Vector2,
    dir2:Vector2,
    r_isect:Vector2):boolean
{
let lmbda, div;

div = dir2.x * dir1.y - dir2.y * dir1.x;

if (div <= 0.0001) {
return false;
}

lmbda = ((v1.y - v2.y) * dir1.x - (v1.x - v2.x) * dir1.y) / div;
r_isect.x = v1.x + lmbda * dir2.x;
r_isect.y = v1.y + lmbda * dir2.y;

return true;
}

export function p_compare_geometric_uv(v1:Vertex,v2:Vertex):number
{
  //NOT WORKING
  if (almostEqual(v1.uv.x,v2.uv.x)) {
    if (almostEqual(v1.uv.y,v2.uv.y)) {
      return 0;
    }

    if (v1.uv.y < v2.uv.y) {
      return -1;
    };
    return 1;
  }

  if (v1.uv.x < v2.uv.x) {
    return -1;
  }
  return 1;
}

export function p_area_signed(v1:Vector2, v2:Vector2, v3:Vector2):number
{
  return 0.5 * (((v2.x - v1.x) * (v3.y - v1.y)) - ((v3.x - v1.x) * (v2.y - v1.y)));
}

export function almostEqual(value1:number, value2:number):boolean
{
  return Math.abs(value1 - value2) < 0.0001;
}

export function angle_v2v2v2(v1:Vector2, v2:Vector2, v3:Vector2):number
{

  let vec1 = new Vector2();
  vec1.subVectors(v2,v1).normalize();

  let vec2 = new Vector2();
  vec2.subVectors(v2,v3).normalize();

  return angle_normalized_v2v2(vec1, vec2);
}  

export function angle_normalized_v2v2(v1:Vector2, v2:Vector2)
{
  if(v1.dot(v2) < 0)
  {
    let vec = v2.clone().negate();
    return Math.PI - 2 * Math.asin(vec.distanceTo(v1) /2);
  }
  else
  {
    return 2 * Math.asin(v2.distanceTo(v1) / 2);
  }
}
export function face_uv_area_signed(f:Face)
{
  return 0.5 * (((f.vertex2.uv.x - f.vertex1.uv.x) * (f.vertex3.uv.y - f.vertex1.uv.y)) -
  ((f.vertex3.uv.x - f.vertex1.uv.x) * (f.vertex2.uv.y - f.vertex1.uv.y)));
}