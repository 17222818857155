import i18next, { Module } from "i18next";
import { initReactI18next } from "react-i18next";

import detect from "i18next-browser-languagedetector";
import trEnPageIntro from "./en/pageIntro.json"
import trFrPageIntro from "./fr/pageIntro.json"
import trEnPageBasics from "./en/pageBasics.json"
import trFrPageBasics from "./fr/pageBasics.json"

const resources = {
    en:{
        pageIntro: trEnPageIntro,
        pageBasics: trEnPageBasics
    },
    fr:{
        pageIntro: trFrPageIntro,
        pageBasics: trFrPageBasics
    }
}


const i18n = i18next.use(initReactI18next).use(detect).use(initReactI18next);
i18n.init({
  resources: resources,
  fallbackLng: 'en',
  supportedLngs: ['en', 'fr'],
  interpolation: {
      escapeValue: false,
  }
});

export default i18next;