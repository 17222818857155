import React, { createRef, MouseEventHandler, SetStateAction } from 'react'
import * as THREE from "three";
import App from './App';
import pjson from "../package.json"
import { Props } from '@react-three/fiber';
import { ContactForm } from './pages/ContactForm';

const GEO1_DEBUG = new THREE.PlaneGeometry(1,1);
const GEO2_DEBUG = new THREE.BoxGeometry(1,1,1);
const GEO3_DEBUG = new THREE.SphereGeometry(1,6,6);
const GEO4_DEBUG = new THREE.ConeGeometry(1,1,6);//Not working
const GEO5_DEBUG = new THREE.CylinderGeometry(1,1,5,6,1); //Problème sélection top
const GEO6_DEBUG = new THREE.IcosahedronGeometry(1);
const GEO7_DEBUG = new THREE.TorusGeometry(1,0.3,8,12); //Torus not working

interface GeoButton {
  nameObject: string;
  onClick: MouseEventHandler;
}
const GeoButton: React.FC<GeoButton> = ({nameObject, onClick}) =>
{
  return <button onClick={onClick}>{nameObject}</button>
}

type HeaderProps = { geoHandle:React.Dispatch<SetStateAction<THREE.BufferGeometry>>};
type HeaderState = { activeElement:THREE.BufferGeometry};

/* export class Header extends React.Component<HeaderProps> {

  state: HeaderState = { activeElement:GEO1_DEBUG}

  setGeometry(geometry:THREE.BufferGeometry)
  {
    this.props.geoHandle(geometry);
  }

  render()
  {
    const {geoHandle} = this.props;
    const {activeElement} = this.state;

    return (
      <ul className = "Header">
        <a href="/">Intro</a>
        <GeoButton onClick={() => this.setGeometry(GEO2_DEBUG)} nameObject="Cube"></GeoButton>
        <GeoButton onClick={() => this.setGeometry(GEO3_DEBUG)} nameObject="Sphere"></GeoButton>
        <GeoButton onClick={() => this.setGeometry(GEO5_DEBUG)} nameObject="Cylinder"></GeoButton>
        <GeoButton onClick={() => this.setGeometry(GEO6_DEBUG)} nameObject="Icosahedron"></GeoButton>
        <GeoButton onClick={() => this.setGeometry(GEO7_DEBUG)} nameObject="Torus"></GeoButton>
        
        <span>  Version : {pjson.version} </span>

      </ul>
    );
  }
} */


export class Header extends React.Component{


  

  constructor(props:Props)
  {
    super(props);
    this.state = {
      showVersion :false,
      showContact : false
    };
  }

  render()
  {
    let target = "_self";
    if(window.location.href.includes("practice"))target="_blank";

    return (<>
      <div className = "Header">
        
        <img className= "logoHeader" src="./logoBlue.svg" alt="logo" style={{marginLeft:"0.3em"}}></img>
        <div className = "contentHeader">
          <ul>
            <li><a href="/intro" target={target} >Intro</a></li>
            
            <li><a href="/basics" target={target} >Basics</a></li>
            <li><a href="/practice" target={target} >Practice</a></li>
          </ul>
          <ul style={{justifyContent:"right", marginRight:"2vw"}}>
            {/* @ts-ignore */}
            <li><button onPointerDown={() => this.setState({showContact:!this.state.showContact})} >Contact</button></li>
            <li><a href="https://alexandre-alves.fr" target="_blank">Alv</a></li>
            <li><a href="https://linktr.ee/alexandrealvesdb" target="_blank">Linktree</a></li>
            <li onMouseEnter={() => this.setState({showVersion:true})} onMouseLeave={() => this.setState({showVersion:false})}><span><b>  Version : {pjson.version} </b></span></li>
          </ul>
        </div >
      </div>
      {/* @ts-ignore */}
      <div className = "Modal" id="version"  style={{ display: this.state.showVersion ? "block" : "none" }}>
          <h3>What's new ?</h3>
          <div className="separator"></div>
          <p>1.3.1 : Updated broken dependencies </p>
          <p>1.3.0 : Change of design for the pages and added buttons to reset view and object </p>
          <p>1.2.1 : Added thickness to lines in the practice part </p>
          <p>1.2.0 : You can now select edges from the UV window and there is an homepage !</p>
      </div>
      {/* @ts-ignore */}
      <div className = "ModalForm"  style={{ display: this.state.showContact ? "block" : "none" }}>
         {/* @ts-ignore */}
        <button className="btnPrimary " id="btnCloseContact" onPointerDown={() => this.setState({showContact:!this.state.showContact})}>Close</button>
          <ContactForm></ContactForm>
      </div>
      </>
    );
  }
}