import * as mathjs from 'mathjs';
import { TranspileOptions } from 'typescript';
import { AppSettings } from '../AppSettings';

enum State { STATE_VARIABLES_CONSTRUCT, STATE_MATRIX_CONSTRUCT, STATE_MATRIX_SOLVED };
class Coeff 
{
  index:number = 0;
  value:number = 0;
  constructor(index:number,value:number)
  {
    this.index = index;
    this.value = value;
  }
}

class Variable{
  locked:boolean;
  index:number;
  value:number[] = [0,0,0,0];

  coeffs:Coeff[] = [];
  constructor()
  {
    this.locked=false;
    this.index=0;
  }
}



export class LinearSolver
  {
    numRows:number;
    numVariables:number;
    numRhs:number;
    m:number = 0;
    n:number = 0;
    compt = 0;
    state:State;
    MTriplets:mathjs.MathNumericType[][] = [];
    sparseLU: { values: mathjs.MathCollection; vectors: mathjs.MathCollection }|null
    matrix: math.Matrix | null;
    matrixT:  math.Matrix | null;
    variable:Array<Variable> = [];//Vector

    b:math.Matrix[]=[];
    x:math.Matrix[]=[];

    constructor(numRows:number, numVariables:number, numRhs:number)
    { 
      //Numvariables 94
        if(numVariables <= 0 || numRhs > 4)console.error("LinearSolver error ", this);
        this.numRows = numRows;
        this.numVariables = numVariables;
        this.numRhs = numRhs;
        this.state = State.STATE_VARIABLES_CONSTRUCT;
        this.sparseLU = null;
        this.matrix = null;
        this.matrixT = null;
        for(let i = 0 ; i<numVariables;i++)
        {
          this.variable.push(new Variable());
        }
        
    }

    solverVariableLock(index:number)
    {
      if(AppSettings.DEBUG)console.log("lock");
      this.variable[index].locked = true;
    }
    solverVariableSet(rhs:number, index:number, value:number)
    {
      this.variable[index].value[rhs] =  value;
    }

    solverVariableGet(rhs:number, index:number)
    {
      return this.variable[index].value[rhs];
    }

    solverMatrixAdd(row:number,col:number,value:number)
    {
      
      if(this.state == State.STATE_MATRIX_SOLVED)return;

      this.solverEnsureMatrixConstruct(); 

     
   
      if (this.variable[col].locked) {
        
        this.variable[col].coeffs.push(new Coeff(row, value));
      }
      else {
        col = this.variable[col].index;
        let v:mathjs.MathArray = [row , col, value];
        this.MTriplets.push(v);
      }
    }

    solverEnsureMatrixConstruct()
    {
      
      if (this.state == State.STATE_VARIABLES_CONSTRUCT) {
        let n = 0;

        for (let i = 0; i < this.numVariables; i++) {
          if (this.variable[i].locked)
           this.variable[i].index = ~0;
          else
           this.variable[i].index = n++;
        }

       let m = (this.numRows == 0) ? n :this.numRows;

       this.m = m;
       this.n = n;

       if(m==n)if(AppSettings.DEBUG)console.log("solver m==n");
        /* reserve reasonable estimate */
       //MTRIPLET RESERVE SIZE  max(m, n) * 3

       for (let i = 0; i < this.numRhs; i++) {

        //C EST CA QUI CASSE TOUT
        this.b[i] = mathjs.zeros(m) as mathjs.Matrix;
        this.x[i] =  mathjs.zeros(n) as mathjs.Matrix;
      }
        this.solverVariablesToVector();

        this.state = State.STATE_MATRIX_CONSTRUCT;
        if(AppSettings.DEBUG)console.log("Constructed");
      }
    }

    solverVariablesToVector()
    {
      let numRhs = this.numRhs;

      for (let i = 0; i < this.numVariables; i++) {
        if (!this.variable[i].locked) {
          for (let j = 0; j < numRhs; j++)
            this.x[j].set([this.variable[i].index], this.variable[i].value[j]);
        }
      }

      if(AppSettings.DEBUG)console.log()
    }

    VectorToVariables()
    {
      let numRhs = this.numRhs;

      for (let i = 0; i < this.numVariables; i++) {
        let v = this.variable[i];
        if (!v.locked) {
          for (let j = 0; j < numRhs; j++)
            v.value[j] = this.x[j].get([v.index])!;
        }
      }
      if(AppSettings.DEBUG)console.log(this);
    }
  Solve():boolean
    {
      
      /* nothing to solve, perhaps all variables were locked */
      if (this.m == 0 || this.n == 0)
        return true;
      if(AppSettings.DEBUG)console.log(this.x);
    
      
      let decomp;
      let result = true;
      let resolved = false;
      //if(this.state != State.STATE_VARIABLES_CONSTRUCT) ;
      
      if (this.state == State.STATE_MATRIX_CONSTRUCT) {
        if(AppSettings.DEBUG)console.log("sovel");
        // create matrix from triplets 
        //this.matrix = eig.SparseMatrix.fromTriplets(this.m, this.n,this.MTriplets);
        this.matrix = mathjs.matrix("sparse");
        this.matrix!.resize([this.m,this.n]);
        if(AppSettings.DEBUG)console.log(this.m, this.n);
        
        this.matrix = setFromTriplets(this.matrix!, this.MTriplets);

       
       if(AppSettings.DEBUG)console.log("This.matrix "  ,this.matrix.toArray());
        // create least squares matrix
        this.matrixT = mathjs.multiply(mathjs.transpose(this.matrix!),this.matrix!);

        
        if(AppSettings.DEBUG)console.log("This.matrixT "  ,this.matrixT.toArray());
        if(AppSettings.DEBUG)console.log("Det ",mathjs.det(this.matrixT));
        //@ts-ignore
        decomp = mathjs.lup(this.matrixT!);
        
        if(AppSettings.DEBUG)console.log("Decomp "  ,decomp);
        this.state = State.STATE_MATRIX_SOLVED;
        
      }
      
      if (result && decomp) {
        // solve for each right hand side 
        
        for (let rhs = 0; rhs < this.numRhs; rhs++) {
          // modify for locked variables 
          let b = this.b[rhs];

          for (let i = 0; i < this.numVariables; i++) {
            let variable = this.variable[i];

            if (variable.locked) {
              let coeffs = variable.coeffs;

              for (let j = 0; j < coeffs.length; j++){
                if(AppSettings.DEBUG)console.log(i, " " , j ," b ", b.get([coeffs[j].index]) , " index ", coeffs[j].index, " value ", coeffs[j].value, " value ", variable.value[rhs])
                b.set([coeffs[j].index], b.get([coeffs[j].index]) - coeffs[j].value * variable.value[rhs]);
              }
              
            }
          }

          
          if(AppSettings.DEBUG)console.log("this.variable ", this.variable);
            
          if(AppSettings.DEBUG)console.log("b",b);
          //if(AppSettings.DEBUG)console.log(mathjs.matrix(decomp.L));
          let Mtb = mathjs.multiply(mathjs.transpose(this.matrix!),b);
          if(AppSettings.DEBUG)console.log(Mtb);
          //@ts-ignore
          let solve = mathjs.lusolve(decomp,Mtb) ;
          
          //if(AppSettings.DEBUG)console.log(mathjs.lusolve(Mtb,this.sparseLU!.vectors));
          if(AppSettings.DEBUG)console.log(solve);
          this.x[rhs]=solve.resize([solve.size()[0]]);
          if(AppSettings.DEBUG)console.log(this.x[rhs]);
          //if(AppSettings.DEBUG)console.log(this.x[rhs]);
          
            
        }

        if (result)
          this.VectorToVariables();
      
        }
      // clear for next solve 
      this.b= [];
      return result;
      
    }
}

function setFromTriplets(matrix:mathjs.Matrix, triplets:mathjs.MathNumericType[][]):mathjs.Matrix
{

  for( let x = 0; x < triplets.length ; x++)
  {
    matrix.set([triplets[x][0] as number,triplets[x][1] as number],triplets[x][2]);
  }

  return matrix;
}