import { Canvas } from "@react-three/fiber";
import { Environment,MeshReflectorMaterial,OrbitControls } from "@react-three/drei";
import type { OrbitControls as OrbitControlsImpl } from 'three-stdlib'

import * as THREE from "three";
import { useRef, useEffect} from "react";
import { EdgeInteraction, EdgeInteractionProps } from "./UVMapper/Components/EdgeInteraction";
import { UVMapper } from "./UVMapper/UVMapper";
import React from "react";
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Noise,
  Vignette
} from "@react-three/postprocessing";
import {NoiseEffect, VignetteEffect} from "postprocessing"
import { SidePanel } from "./UVMapper/Components/SidePanel";
import { useState } from "react";
import { AppSettings } from "./AppSettings";
import  cameraIcon from "./camera-black.svg";
import reset from "./reset.svg";



export function UVView(props:{geo : THREE.BufferGeometry, goals:{stretchGoal:number|null, seamsGoal:number|null}, callFunction:() => void}
){
  let objectGeometry = props.geo;
  const canvas2DObject:HTMLCanvasElement= document.createElement("canvas");
  const controls = useRef<OrbitControlsImpl>(null);
  canvas2DObject.setAttribute("id", "canvas2D");
  const canvasDiv = useRef<HTMLDivElement>(null);
  const edgeInteract = useRef<EdgeInteractionProps>(null);
  const sidePanel = useRef<SidePanel>(null);
  const goals = props.goals
  const practiceFunction = props.callFunction;
  const resetEdgesButton = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    canvasDiv.current?.appendChild(canvas2DObject);

    const onKeyUp = (event: KeyboardEvent) => {
      if (event.code === "KeyF") 
      {
        resetView();
      }

    };

    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);
  
  function resetView()
  {

    controls.current?.reset?.();
  }




  useEffect(() => {
    if(AppSettings.DEBUG)console.log("Props changed")
  }, [props.geo]);

  return (
    <div id="uvMapper" >
      <div id="canvas-container">
        <button className = "insideCanvas" onClick={()=>resetView()}><img src={cameraIcon}></img></button>
        <button className = "insideCanvas offset" ref={resetEdgesButton}><img src={reset}></img></button>
        <Canvas
          camera={{
            position: [0, 3, 3],
            fov: 60,
            near: 0.01,
            far: 1000,
          }}
        >
          <EffectComposer>
            <DepthOfField focusDistance={0} focalLength={0.05} bokehScale={2} height={480} />
          </EffectComposer>
          {/* 
// @ts-ignore */}
          <OrbitControls ref={controls} enablePan={false} />
          <color attach="background" args={["#A7C7E7"]} />
          <EdgeInteraction
            geometry={objectGeometry}
            canvas2DObject={canvas2DObject}
            sidePanel={sidePanel}
            ref={edgeInteract}
            goals={goals}
            practiceFunction={practiceFunction}
            resetButton={resetEdgesButton}
          />
          <mesh rotation={[-Math.PI / 2, 0, 0]} position ={[0,-3,0]}>
            <planeGeometry  args={[1000,1000]}/>
            <MeshReflectorMaterial
              blur={[300, 100]}
              resolution={2048}
              mixBlur={1}
              roughness={0.2}
              mirror={1}/>
          </mesh>
          <Environment preset="city" />
        </Canvas>
      </div>
      <div id="side" >
        <div id="canvas" ref={canvasDiv}></div>
        <SidePanel ref={sidePanel} />
      </div>
    </div>
  );
}
