import basics_artefact from "../img/Basics/Basics_Artefact.png"
import basics_artefactUVMap from "../img/Basics/Basics_ArtefactUVMap.png"
import intro_checkerConeAuto from "../img/Intro/Intro_CheckerConeAuto.png"
import basics_checkerConeAutoMap from "../img/Basics/Basics_CheckerConeAutoMap.png"
import basics_checkerCone from "../img/Basics/Basics_CheckerCone.gif"
import basics_cylinderCut from "../img/Basics/Basics_CylinderCut.gif"
import basics_cylinderNoCut from "../img/Basics/Basics_CylinderNoCut.gif"
import basics_capsule from "../img/Basics/Basics_Capsule.png"
import basics_capsuleDecomp from "../img/Basics/Basics_CapsuleDecomp.png"
import basics_compoundStep1 from "../img/Basics/Basics_Compound_Step1.png"
import basics_compoundStep2 from "../img/Basics/Basics_Compound_Step2.png"
import basics_compoundUVStep1 from "../img/Basics/Basics_CompoundUV_Step1.png"
import basics_compoundUVStep2 from "../img/Basics/Basics_CompoundUV_Step2.png"
import basics_icoStep1 from "../img/Basics/Basics_Ico_Step1.png"
import basics_icoStep2 from "../img/Basics/Basics_Ico_Step2.png"
import basics_icoUVStep1 from "../img/Basics/Basics_IcoUV_Step1.png"
import basics_icoUVStep2 from "../img/Basics/Basics_IcoUV_Step2.png"
import basics_overlap from "../img/Basics/Basics_Overlap.png"
import basics_overlapUV from "../img/Basics/Basics_OverlapUV.png"
import basics_paddingUV from "../img/Basics/Basics_PaddingUV.png"
import basics_paddingUVLowRes from "../img/Basics/Basics_PaddingUV_LowRes.png"
import { Trans, useTranslation } from "react-i18next"

export function PageBasics() {
    const {t} = useTranslation("pageBasics");
    return (
      <div className="page">
        <h1>{t("basics")}</h1>
        <h2>{t("firstTitle")}</h2>
            <div className="sectionParagraphe-column">
                <Trans><p>{t("reminderSeams")}</p></Trans>
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_artefact} alt="Cone with texture artefact (seam)"></img>
                        <figcaption>{t("coneSeamArtifact")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_artefactUVMap} alt="UVMap of cone with texture artefact (seam)"></img>
                        <figcaption>{t("coneSeamArtifactUV")}</figcaption>
                    </figure>
                </div>
                <Trans><p>{t("faceArtifacts")}</p></Trans>
            </div>
            <div className="sectionParagraphe-column">
                <Trans><p>{t("stretchArtifacts")}</p></Trans>
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={intro_checkerConeAuto} alt="Cone with texture artefact (stretch)"></img>
                        <figcaption>{t("coneStretchArtifact")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_checkerConeAutoMap} alt="UVMap of cone with texture artefact (stretch)"></img>
                        <figcaption>{t("coneStretchArtifactUV")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_checkerCone} alt="Animated cone with texture artefact (stretch)"></img>
                        <figcaption>{t("coneStretchArtifactGIF")}</figcaption>
                    </figure>
                </div>
            </div>

        <h2>{t("secondTitle")}</h2>
            <div className="sectionParagraphe-column">
                <p>{t("whySeams")}</p>
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_cylinderCut} alt="Cylinder with seam"></img>
                        <figcaption>{t("cylinderSeam")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_cylinderNoCut} alt="Cylinder without seam"></img>
                        <figcaption>{t("cylinderNoSeam")}</figcaption>
                    </figure>
                </div>
                <div className="information">
                    <p>{t("sphereDeform")}</p>
                </div>
            </div>


        <h2>{t("thirdTitle")}</h2>
            <div className="sectionParagraphe-column">
                <p>{t("chooseSeams")}</p>
                <div className="important">
                    <ul>
                        <li>{t("minSeams")}</li>
                        <li>{t("minStretch")}</li>
                        <li>{t("hiddenSeams")}</li>
                    </ul>
                </div>
            </div>

        <h2>{t("fourthTitle")}</h2>
            <div className="sectionParagraphe-column">
                <Trans><p>{t("breakdownContent")}</p></Trans>
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_capsule} alt="Capsule"></img>
                        <figcaption>{t("capsule")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_capsuleDecomp} alt="Capsule decomposition"></img>
                        <figcaption>{t("capsuleBreakdown")}</figcaption>
                    </figure>
                </div>
                <div className="important">
                    <p>{t("breakdownShell")}</p>
                </div>
            </div>
        
        <h2>{t("fifthTitle")}</h2>
            <div className="sectionParagraphe-column">
                <div className="information">
                <Trans><p>{t("sewCutInfo")}</p></Trans>
                </div>
                <p>{t("sewCutWorkflows")}</p>
            </div>
            
            <div className="sectionParagraphe-column">
                <p><b>{t("workflowCut")}</b></p>
                <ul>
                    <li>{t("workflowCutList1")}</li>
                    <li>{t("workflowCutList2")}</li>
                    <li>{t("workflowCutList3")}</li>
                    <li>{t("workflowCutList4")}</li>
                </ul>
                
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_compoundStep1} alt="Lego - Step 1"></img>
                        <figcaption>{t("legoStep1")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_compoundUVStep1} alt="Lego UV - Step 1"></img>
                        <figcaption>{t("legoStep1UV")}</figcaption>
                    </figure>
                </div>
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_compoundStep2} alt="Lego - Step 2"></img>
                        <figcaption>{t("legoStep2")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_compoundUVStep2} alt="Lego UV - Step 2"></img>
                        <figcaption>{t("legoStep2UV")}</figcaption>
                    </figure>
                </div>
    
            </div>
            <div className="sectionParagraphe-column">
                <p><b>{t("workflowSew")}</b></p>
                    <ul>
                        <li>{t("workflowSewList1")}</li>
                        <li>{t("workflowSewList2")}</li>
                        <li>{t("workflowSewList3")}</li>
                    </ul>
                
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_icoStep1} alt="Icosphere - Step 1"></img>
                        <figcaption>{t("icoStep1")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_icoUVStep1} alt="Icosphere UV - Step 1"></img>
                        <figcaption>{t("icoStep1UV")}</figcaption>
                    </figure>
                </div>

                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_icoStep2} alt="Icosphere - Step 2"></img>
                        <figcaption>{t("icoStep2")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_icoUVStep2} alt="Icosphere UV - Step 2"></img>
                        <figcaption>{t("icoStep2UV")}</figcaption>
                    </figure>
                </div>
            </div>
            <div className="sectionParagraphe-column">
            <p>{t("multipleWorkflows")}</p>
                <div className="information">
                    <p>{t("justTry")}</p>
                </div>
                
            </div>

        
        <h2>{t("sixthTitle")}</h2>
            <div className="sectionParagraphe-column">
                <p>{t("uvShellContent")}</p>
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_overlap} alt="Cone with overlapping UV islands"></img>
                        <figcaption>{t("overlapCone")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_overlapUV} alt="UV Map of a cone with overlapping UV islands"></img>
                        <figcaption>{t("overlapConeUV")}</figcaption>
                    </figure>
                </div>
            </div>
            <div className="sectionParagraphe-column">
                <Trans><p>{t("padding")}</p></Trans>
                <div className="img-wrapper">
                    <figure className ="img-card">
                        <img src={basics_paddingUV} alt="UV Map of a cone with no padding on UV islands"></img>
                        <figcaption>{t("noPaddingCone")}</figcaption>
                    </figure>
                    <figure className ="img-card">
                        <img src={basics_paddingUVLowRes} alt="Low resolution UV Map of a cone with no padding on UV islands"></img>
                        <figcaption>{t("noPaddingConeLowRes")}</figcaption>
                    </figure>
                </div>
                <p>{t("lod")}</p>
                <div className="information">
                    <p>{t("infoShell")}</p>
                </div>
            </div>
        
        <h2>{t("seventhTitle")}</h2>
            <div className="sectionParagraphe-column centered">
                <p>{t("multipleSolutions")}</p>
                <div className="important">
                    <ul>
                        <li>{t("minSeams")}</li>
                        <li>{t("minStretch")}</li>
                        <li>{t("hiddenSeams")}</li>
                    </ul>
                </div>
            </div>
            <a href="practice" className="nextButton"> {t("next")}</a>
            <br></br>
        </div>
    );
  }