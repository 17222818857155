import * as THREE from "three";
import { UVView } from "./UVView";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import { Suspense, useEffect, useState } from "react";
import * as Geometry from "./cylinder.obj";
import React from "react";
import {Header} from "./Header";
import { PageIntro } from "./pages/PageIntro";
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { PageBasics } from "./pages/PageBasics";
import "./locale/i18n"
import { ContactForm } from "./pages/ContactForm";
import { HomePage } from "./pages/HomePage";

const GEO1_DEBUG = new THREE.PlaneGeometry(1,1);
const GEO2_DEBUG = new THREE.BoxGeometry(1,1,1);
const GEO3_DEBUG = new THREE.SphereGeometry(1,6,6);
const GEO4_DEBUG = new THREE.ConeGeometry(1,1,6);
const GEO5_DEBUG = new THREE.CylinderGeometry(1,1,5,6,1);
const GEO6_DEBUG = new THREE.IcosahedronGeometry(1);
const GEO7_DEBUG = new THREE.TorusGeometry(1,0.3,8,12);
const loader = new OBJLoader();

class PracticePage
{
  geometry:THREE.BufferGeometry
  goals:{stretchGoal:number|null, seamsGoal:number|null}
  constructor(geometry:THREE.BufferGeometry, goals:{stretchGoal:number|null, seamsGoal:number|null})
  {
    this.geometry = geometry;
    this.goals = goals;
  }
}


const torusPage = new PracticePage(GEO7_DEBUG, {stretchGoal:10, seamsGoal:32});
const spherePage = new PracticePage(GEO3_DEBUG, {stretchGoal:11.5, seamsGoal:17});
const icosahedronPage = new PracticePage(GEO6_DEBUG, {stretchGoal:0.01, seamsGoal:12});
const cylinderPage = new PracticePage(GEO5_DEBUG, {stretchGoal:0.01, seamsGoal:13});
const cubePage = new PracticePage(GEO2_DEBUG, {stretchGoal:0.01, seamsGoal:7});

const practicePages = [cubePage, cylinderPage, icosahedronPage, spherePage, torusPage];


let index = 0;
function App() {
  //const [endload, set] = useState(false);
  //@ts-ignore
  //loader.load(Geometry.default,(object)=>{console.log(object.getObjectByName("Cylinder")); geometry=object.getObjectByName("Cylinder").geometry; set(true)},(xhr) => {console.log((xhr.loaded / xhr.total) * 100 + '% loaded')},(error) => {console.log(error)})

  let [practicePage, setPracticePage] = React.useState(cubePage);
  function changePracticePage()
  {
    index++;
    if(index < practicePages.length)
    {
      setPracticePage(practicePages[index]);
   
    }
    else 
    {
      window.alert("It's all for now, good job !");
    }
    

  }
  /*
  return (
    
    <Suspense>
      
      <UVView geo={geometry} />
      
    </Suspense>
  );
  */
 return (
  
 <BrowserRouter>
 
    <Routes>
      <Route path="/" element={<><Header/><HomePage/></>} >
      </Route>
      <Route path="/intro" element={<><Header/><PageIntro></PageIntro></>} >

      </Route>
      <Route path="/basics" element={<><Header/><PageBasics></PageBasics></>} >

      </Route>

{/*         
      <Route path="/header" element={<>
         <Header geoHandle={setGeometry}/>
         <UVView geo={geometry} goals={defaultGoals} callFunction = {changePracticePage}/>
         </>
      }>
        </Route> */}

      <Route path="/practice" element={<>
         <Header/>
         <UVView geo={practicePage.geometry} goals={practicePage.goals} callFunction={changePracticePage} />
         </>
      }>
       
      </Route>
        
    </Routes>
  </BrowserRouter>
  );
}
/*
    <Suspense>
      {endload && <UVView geo={geometry} />}
      
    </Suspense>
*/
export default App;
/*
<EffectComposer multisampling={0} disableNormalPass={true}>
<Noise opacity={0.025} />
<Vignette eskil={true} offset={0.2} darkness={1.1} />
</EffectComposer>
*/