import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import "./css/Header.css";
import "./css/Page.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <link rel="preconnect" href="https://fonts.googleapis.com"></link>
    <link
      rel="preconnect"
      href="https://fonts.gstatic.com"
      crossOrigin="true"
    ></link>
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"
      rel="stylesheet"
    ></link>
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto%20Slab:wght@400&display=swap"
      rel="stylesheet"
    ></link>
    <App />
  </React.StrictMode>
);

reportWebVitals();
