import { Edge, Face, UniqueVertex, Vertex } from "./Geo";
import { Vector3, Vector2, Line3} from "three";
import { LinearSolver } from "./LinearSolver";
import {angleTriVector2, angle_v2v2v2, p_area_signed, p_compare_geometric_uv, p_rectangle_area, Shift3} from "./Utils"
import {AppSettings} from "../AppSettings"

interface ChartLSCM{
  context:LinearSolver|null;
  pin1:Vertex|null , pin2:Vertex|null;

}
interface ChartPack {
  rescale:number, area:number;
  size:Vector2;
  origin:Vector2;
}


export class Chart {
    chartLSCM:ChartLSCM | undefined;
    chartPack: ChartPack;
    indexChart:number;
    faces:Array<Face>;
    facesIndexes:Array<number>;
    vertices:Array<Vertex>;
    borderEdges:Array<Edge>;
    uvCentroid:Vector2;
    pin1:Vertex|null;
    pin2:Vertex|null;
    minV = new Vector2(1e20,1e20);
    maxV  = new Vector2(-1e20,-1e20);
    idChart = 0;

    constructor(indexChart:number, faces:Array<Face> = []) {
      this.indexChart = indexChart;
      this.faces = [];
      this.facesIndexes = [];
      this.borderEdges = [];
      this.vertices = [];
      this.uvCentroid = new Vector2(0, 0);
      this.pin1 = null;
      this.pin2 = null;
      this.chartLSCM = undefined;
      this.chartPack = {rescale:1, area:0,size:new Vector2(0,0), origin:new Vector2(0.5,0.5)};
    }
    
    uvScale(scale:number)
    {
      for( let f of this.faces)
      { 
        f.uvScale(scale);
      }
      this.chartPack.size.multiplyScalar(scale);
      this.chartPack.origin.multiplyScalar(scale);
    }

    uvTranslate(trans:Vector2)
    {
      for( let f of this.faces)
      { 
        f.uvTranslate(trans);
      }
      this.chartPack.origin.add(trans);
    }

    calculateCurrentCenter()
    {
      this.minV = new Vector2(1e20,1e20);
      this.maxV  = new Vector2(-1e20,-1e20);
      for (let v of this.vertices) {
        if(v.uv.x < this.minV.x) this.minV.x = v.uv.x;
        if(v.uv.y < this.minV.y) this.minV.y = v.uv.y;
        if(v.uv.x > this.maxV.x) this.maxV.x = v.uv.x;
        if(v.uv.y > this.maxV.y) this.maxV.y = v.uv.y;
      }
      if(AppSettings.DEBUG)console.log("minMax",this.minV, this.maxV);
      this.chartPack.size.subVectors(this.maxV, this.minV);
      this.uvCentroid.addVectors(this.minV, this.maxV).multiplyScalar(0.5);
      this.chartPack.origin.addVectors(this.minV, this.maxV).multiplyScalar(0.5);
    }
    
    addFace(face:Face) {
      this.faces.push(face);
      let vertices = face.getVertices();

      let addOne = true, addTwo = true, addThree = true;
      for( let i = 0; i< this.vertices.length ;i++)
      {
        //Marche pas car on a pas opposite face
        //On doit ajouter uniquement les vertices unique donc pas doublon pour edge en commun et attention oppositeEdge and edgeseam on ajoute
        if(vertices[0].equals(this.vertices[i])) 
        {
          vertices[0].idChart = this.vertices[i].idChart;
          addOne = false;
        }

        if(vertices[1].equals(this.vertices[i])) 
        {
          vertices[1].idChart = this.vertices[i].idChart;
          addTwo = false;
        }
        if(vertices[2].equals(this.vertices[i])) 
        {
          vertices[2].idChart = this.vertices[i].idChart;
          addThree = false;
        }
      }

      if(addOne)
      {
        vertices[0].idChart = this.idChart++;
        this.vertices.push(vertices[0]);
      }
      if(addTwo)
      {
        vertices[1].idChart = this.idChart++;
        this.vertices.push(vertices[1]);
      }
      if(addThree)
      {
        vertices[2].idChart = this.idChart++;
        this.vertices.push(vertices[2]);
      }
      
      this.facesIndexes.push(face.indexFace);
    }
    
    calculate() {
      for (let i = 0; i < this.faces.length; i++) {
        this.uvCentroid.add(this.faces[i].uvCentroid);
  
        for (
          let edgeIndex = 0;
          edgeIndex < this.faces[i].edges.length;
          edgeIndex++
        ) {
          if (this.faces[i].edges[edgeIndex].edgeSeam) {
            
            this.borderEdges.push(this.faces[i].edges[edgeIndex]);
          }
        }

      } 


      this.uvCentroid.divideScalar(this.faces.length);
      this.findExtremesChart();
      
      this.chartLSCM = {context:new LinearSolver(
        2 * this.faces.length, 2 * this.vertices.length, 1),pin1:this.pin1,pin2:this.pin2};
      if(AppSettings.DEBUG)console.log(this);
    }

    applyUVToVertices()
    {
      for( let f of this.faces)
      {
        for(let v of this.vertices)
        {
          if(f.vertex1.idChart == v.idChart)
          {
            f.vertex1.uv.copy(v.uv);
          }
          if(f.vertex2.idChart == v.idChart)
          {
            f.vertex2.uv.copy(v.uv);
          }
          if(f.vertex3.idChart == v.idChart)
          {
            f.vertex3.uv.copy(v.uv);
          }
        }
        
      }
     
    }

    isFaceInsideChart(faceIndex: number|undefined)
    {
        if(!faceIndex)return false;
        return this.facesIndexes.indexOf(faceIndex) != -1;
    }
    
    tightenChart()
    {
        
        for (let i = 0; i < this.faces.length; i++) {
          this.faces[i].vertex1.uv.lerp(this.uvCentroid, 0.01);
          this.faces[i].vertex2.uv.lerp(this.uvCentroid, 0.01);
          this.faces[i].vertex3.uv.lerp(this.uvCentroid, 0.01);
        }
    }

    findExtremesChart()
    {
      let dirLength = -1;
      let minV:number[] = [],maxV:number[]= [];
      let minVert:Vertex[] = [], maxVert:Vertex[] = [];
      let dir = 0;
      for (let i = 0; i < 3; i++) {
        minV.push(1e10);
        maxV.push(-1e10);
      }

      //Find extremes on xyz of vertices
      for (let vertex = 0; vertex < this.vertices.length ; vertex++) {
        for (let i = 0; i < 3; i++) {
          if(this.vertices[vertex].getCoordinatePos(i)<minV[i])
          {
            minV[i] = this.vertices[vertex].getCoordinatePos(i);
            minVert[i] = this.vertices[vertex];
           
          }
          if(this.vertices[vertex].getCoordinatePos(i)>maxV[i])
          {
            maxV[i] = this.vertices[vertex].getCoordinatePos(i);
            maxVert[i] = this.vertices[vertex];
          }
        }
      }
      
      /* find axes with longest distance */
      dir = 0;
      for (let i = 0; i < 3; i++) {
        if(maxV[i] - minV[i] > dirLength)
        {
          dir = i;
          dirLength = maxV[i] - minV[i] ;
        }
      }

  
    
      this.pin1 = minVert[dir];
      this.pin2 = maxVert[dir];

      
      this.setUVPins();
      if(AppSettings.DEBUG)console.log("Chart ", this);
      if(AppSettings.DEBUG)console.log("PIN1 ", this.pin1);
      if(AppSettings.DEBUG)console.log("PIN2 ", this.pin2);
    }

    setUVPins()
    {
      if (!this.pin1 || !this.pin2 || this.pin1 == this.pin2) {
        /* degenerate case */
        this.pin1 = this.vertices[0];
        this.pin2 = this.vertices[1];
        
        this.pin1.uv = new Vector2(0,0.5);
        this.pin2.uv = new Vector2(1,0.5);
      }
      else {
        let diru, dirv, dirx, diry;
        let sub:number[] = [];
        sub[0] = Math.abs(this.pin1.position.x - this.pin2.position.x);
        sub[1] = Math.abs(this.pin1.position.y - this.pin2.position.y);
        sub[2] = Math.abs(this.pin1.position.z - this.pin2.position.z);
    
        if ((sub[0] > sub[1]) && (sub[0] > sub[2])) {
          dirx = 0;
          diry = (sub[1] > sub[2]) ? 1 : 2;
        }
        else if ((sub[1] > sub[0]) && (sub[1] > sub[2])) {
          dirx = 1;
          diry = (sub[0] > sub[2]) ? 0 : 2;
        }
        else {
          dirx = 2;
          diry = (sub[0] > sub[1]) ? 0 : 1;
        }
    
        if (dirx == 2) {
          diru = 1;
          dirv = 0;
        }
        else {
          diru = 0;
          dirv = 1;
        }
        
        this.pin1.setCoordinateUV(diru,this.pin1.getCoordinatePos(dirx));
        this.pin1.setCoordinateUV(dirv,this.pin1.getCoordinatePos(diry));
        this.pin2.setCoordinateUV(diru,this.pin2.getCoordinatePos(dirx));
        this.pin2.setCoordinateUV(dirv,this.pin2.getCoordinatePos(diry));

      }
    }

    rotateMinimumArea()
    {

      //You are next
      let angle = this.minimumAreaAngle();
      let sine = Math.sin(angle);
      let cosine = Math.cos(angle);
      if(AppSettings.DEBUG)console.log(sine, cosine);
      for (let v of this.vertices) {
        let oldu = v.uv.x, oldv = v.uv.y;
        v.uv.x = cosine * oldu - sine * oldv;
        v.uv.y = sine * oldu + cosine * oldv;

        if(v.uv.x < this.minV.x) this.minV.x = v.uv.x;
        if(v.uv.y < this.minV.y) this.minV.y = v.uv.y;
        if(v.uv.x > this.maxV.x) this.maxV.x = v.uv.x;
        if(v.uv.y > this.maxV.y) this.maxV.y = v.uv.y;
      }
      this.applyUVToVertices();
    }

    
    minimumAreaAngle()
    {
      /* minimum area enclosing rectangle with rotating calipers, info:
      * http://cgm.cs.mcgill.ca/~orm/maer.html */

      let rotated, minarea, minangle, area, len;
      let angles:number[] = [], miny, maxy, v:Vector2 = new Vector2(), a:number[] = [], mina;
      let npoints= 0 , right =0, i_min, i_max, i, idx:number[]= [], nextidx;
      let points:Vertex[] = [], p1:Vertex, p2:Vertex, p3:Vertex, p4:Vertex, p1n:Vertex;

      let idCharts:number[] = [];
      for(let i = 0; i< this.borderEdges.length; i++)
      {
        if(i!= this.borderEdges.length - 1)
          if(!(this.borderEdges[i].vertex1.idChart in idCharts)){
            idCharts.push(this.borderEdges[i].vertex1.idChart);
            points.push(this.borderEdges[i].vertex1);
          }
          if(!(this.borderEdges[i].vertex2.idChart in idCharts))
          {
            idCharts.push(this.borderEdges[i].vertex2.idChart);
            points.push(this.borderEdges[i].vertex2);
          }
          
      }
      /* compute convex hull */

      let result = p_chart_convex_hull(this, {points, npoints, right});
      if(result == false)
      {
        return 0;
      }
      else
      {
        points = result.points;
        npoints = result.npoints;
        right = result.right;
      }


      
      /* find left/top/right/bottom points, and compute angle for each point */
      i_min = i_max = 0;
      miny = 1e10;
      maxy = -1e10;
      if(AppSettings.DEBUG)console.log("points",points);
      for (let i = 0; i < npoints; i++) {
        p1 = (i == 0) ? points[npoints - 1] : points[i - 1];
        p2 = points[i];
        p3 = (i == npoints - 1) ? points[0] : points[i + 1];

        //Bricolage ? Faire un autre pvec2Angle
        angles[i] = Math.PI - angle_v2v2v2(p1.uv, p2.uv, p3.uv);
        
        //Mauvais IDX
        if (points[i].uv.y < miny) {
          miny = points[i].uv.y;
          i_min = i;
        }
        if (points[i].uv.y > maxy) {
          maxy = points[i].uv.y;
          i_max = i;
        }
      }
      /* left, top, right, bottom */
      idx[0] = 0;
      idx[1] = i_max;
      idx[2] = right;
      idx[3] = i_min;

      if(AppSettings.DEBUG)console.log("idx",idx);
      v.x = points[idx[0]].uv.x;
      v.y = points[idx[0]].uv.y + 1.0;
      
      //Probleme de a
      a[0] = angle_v2v2v2(points[(idx[0] + 1) % npoints].uv, points[idx[0]].uv, v);

      v.x = points[idx[1]].uv.x + 1.0;
      v.y = points[idx[1]].uv.y;
      a[1]  =  angle_v2v2v2(points[(idx[1] + 1) % npoints].uv, points[idx[1]].uv, v);

      v.x = points[idx[2]].uv.x;
      v.y = points[idx[2]].uv.y - 1.0;
      a[2]  =  angle_v2v2v2(points[(idx[2] + 1) % npoints].uv, points[idx[2]].uv, v);

      v.x = points[idx[3]].uv.x - 1.0;
      v.y = points[idx[3]].uv.y;
      a[3]  =  angle_v2v2v2(points[(idx[3] + 1) % npoints].uv, points[idx[3]].uv, v);

      /* 4 rotating calipers */

      rotated = 0.0;
      minarea = 1e10;
      minangle = 0.0;

      let compt = 0;

      while (rotated <= Math.PI / 2) { /* INVESTIGATE: how far to rotate? */
        /* rotate with the smallest angle */
        i_min = 0;
        mina = 1e10;

        for (i = 0; i < 4; i++) {
          if (a[i] < mina) {
            mina = a[i];
            i_min = i;
          }
        }

        rotated += mina;
        nextidx = (idx[i_min] + 1) % npoints;

        a[i_min] = angles[nextidx];
        a[(i_min + 1) % 4] = a[(i_min + 1) % 4] - mina;
        a[(i_min + 2) % 4] = a[(i_min + 2) % 4] - mina;
        a[(i_min + 3) % 4] = a[(i_min + 3) % 4] - mina;

        /* compute area */
        p1 = points[idx[i_min]];
        p1n = points[nextidx];
        p2 = points[idx[(i_min + 1) % 4]];
        p3 = points[idx[(i_min + 2) % 4]];
        p4 = points[idx[(i_min + 3) % 4]];

        len = p1.uv.distanceTo(p1n.uv);

        if (len > 0.0) {
          len = 1.0 / len;
          v.x = (p1n.uv.x - p1.uv.x) * len;
          v.y = (p1n.uv.y - p1.uv.y) * len;

          area = p_rectangle_area(p1.uv, v, p2.uv, p3.uv, p4.uv);
          /* remember smallest area */
          if (area < minarea) {
            minarea = area;
            minangle = rotated;
          }
        }

        idx[i_min] = nextidx;
      }


      /* try keeping rotation as small as possible */
      if (minangle > Math.PI / 4) {
        minangle -= Math.PI / 2;
      }


      return minangle;
    }

  }
  

  export function ChartLSCMSolve(chart:Chart):boolean
  {
    let context = chart.chartLSCM?.context;
    let pin1 =  chart.chartLSCM!.pin1;
    let pin2 =  chart.chartLSCM!.pin2;

    let f:Face;

    let areaPinnedUp:number = 0,areaPinnedDown:number = 0;
    let flipFaces:boolean;
  
   
    if (pin1) {
      
      context!.solverVariableLock(2 * pin1.idChart);
      context!.solverVariableLock(2 * pin1.idChart + 1);
      context!.solverVariableLock(2 * pin2!.idChart);
      context!.solverVariableLock(2 * pin2!.idChart + 1);
  
      context!.solverVariableSet(0, 2 * pin1.idChart, pin1.uv.x);
      context!.solverVariableSet(0, 2 * pin1.idChart + 1, pin1.uv.y);
      context!.solverVariableSet(0, 2 * pin2!.idChart, pin2!.uv.x);
      context!.solverVariableSet(0, 2 * pin2!.idChart + 1, pin2!.uv.y);
    }
    if(AppSettings.DEBUG)console.log("AFTER SOLVER PIN1", context);

   flipFaces = false;
  
    /* construct matrix */
  
    let row = 0;
  
    for (let i = 0; i<chart.faces.length ;i++) {
      //Notsure
      let vertices = {v1 : chart.faces[i].vertex1, v2 : chart.faces[i].vertex2, v3 :chart.faces[i].vertex3};
      
      let  ratio, cosine, sine;
      let  sinmax;
        
      //Face get angles to a1 a2 a3
      let angles = chart.faces[i].getTrianglesAngle();
      
      let sinAngles = {v1: Math.sin(angles.v1),v2: Math.sin(angles.v2),v3:Math.sin(angles.v3)}

      sinmax = Math.max(sinAngles.v1, sinAngles.v2, sinAngles.v3);
      
      /* shift vertices to find most stable order */ //Validé
      
      if (sinAngles.v3 != sinmax) {
        Shift3(vertices);
        Shift3(angles);
        Shift3(sinAngles);
        if (sinAngles.v2 == sinmax) {
          Shift3(vertices);
          Shift3(angles);
          Shift3(sinAngles);
        }
      }
      
      /* angle based lscm formulation */
      ratio = (sinAngles.v3 == 0) ? 1.0 : sinAngles.v2 / sinAngles.v3;
      cosine = Math.cos(angles.v1) * ratio;
      sine = sinAngles.v1 * ratio;
      
      if(AppSettings.DEBUG)console.log(i);
      if(AppSettings.DEBUG)console.log(cosine, sine);
      //On devrait avoir 10 valeurs sur les vertices locked
      //Mtriplets 560 (valeurs lock manquantes)

      context!.solverMatrixAdd( row, 2 * vertices.v1.idChart, cosine - 1.0);
      context!.solverMatrixAdd( row, 2 * vertices.v1.idChart + 1, -sine);
      context!.solverMatrixAdd( row, 2 * vertices.v2.idChart, -cosine);
      context!.solverMatrixAdd( row, 2 * vertices.v2.idChart + 1, sine);
      context!.solverMatrixAdd( row, 2 * vertices.v3.idChart, 1.0);
      row++;
  
      context!.solverMatrixAdd( row, 2 * vertices.v1.idChart, sine);
      context!.solverMatrixAdd( row, 2 * vertices.v1.idChart + 1, cosine - 1.0);
      context!.solverMatrixAdd( row, 2 * vertices.v2.idChart, -sine);
      context!.solverMatrixAdd( row, 2 * vertices.v2.idChart + 1, -cosine);
      context!.solverMatrixAdd( row, 2 * vertices.v3.idChart + 1, 1.0);
      row++;
    }
    if(AppSettings.DEBUG)console.log(context);
  
    if (context!.Solve()) {
      
      
      for(let v of chart.vertices)
      {
        v.uv.x= context!.solverVariableGet(0,2 * v.idChart);
        v.uv.y= context!.solverVariableGet(0,2 * v.idChart + 1);
      }
      context = null;
      return true;
    }
    
    for (let v of chart.vertices) {
      v.uv.x = 0;
      v.uv.y = 0;
    }
  
    return false;
  }
  
  export function p_chart_convex_hull(chart:Chart, ref: {points:Vertex[],npoints:number,right:number })
  {
    if (chart.borderEdges.length == 0)return false;

    let L = [], U = [];
    let nPoints = chart.borderEdges.length;
    ref.points = ref.points.sort(p_compare_geometric_uv);

    if(AppSettings.DEBUG)console.log(ref.points);
    let ulen = 0, llen = 0;

    for (let p of ref.points) {
      while ((ulen > 1) && (p_area_signed(U[ulen - 2].uv, p.uv, U[ulen - 1].uv) <= 0)) {
        ulen--;
      }
      while ((llen > 1) && (p_area_signed(L[llen - 2].uv, p.uv, L[llen - 1].uv) >= 0)) {
        llen--;
      }
  
      U[ulen] = p;
      ulen++;
      L[llen] = p;
      llen++;
    }
  
    ref.npoints = 0;
    for (let i = 0; i < ulen; i++) {
      ref.points[i] = U[i];
    }
    
    let indexP = 0;
    nPoints = 0;
    /* the first and last point in L are left out, since they are also in U */
    for (let i = 0; i < ulen ; i++) {
      ref.points[indexP] = U[i];
      indexP++;
      nPoints++;
    }
    for (let i = llen - 2; i > 0 ; i--) {
      ref.points[indexP] = L[i];
      indexP++;
      nPoints++;
    }

    ref.npoints = nPoints;
    ref.right = ulen - 1;
   
    return ref;
  }