var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class BaseUVUnwrapper {
    /**
     *
     * @param THREE - for reference to BufferAttribute
     * @param packOptions - options for packing
     * @param chartOptions - options for unwrapping
     * @param useNormals - If true, will use the normals to calculate the uv
     * @param timeUnwrap - Logs the time taken to unwrap geometries
     * @param logProgress - Logs the unwrapping progress
     */
    constructor(THREE, packOptions = {
        resolution: 2048,
    }, chartOptions = {}, useNormals = false, timeUnwrap = false, logProgress = false) {
        this.THREE = THREE;
        this.packOptions = packOptions;
        this.chartOptions = chartOptions;
        this.useNormals = useNormals;
        this.timeUnwrap = timeUnwrap;
        this.logProgress = logProgress;
        this._libraryLoaded = false;
        this._isUnwrapping = false;
        this.xAtlas = this._createXAtlas();
    }
    loadLibrary(onProgress, wasmFilePath, workerFilePath) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._libraryLoaded)
                return;
            yield new Promise((resolve, reject) => {
                try {
                    this.xAtlas.init(() => {
                        resolve();
                    }, onProgress, wasmFilePath, workerFilePath);
                }
                catch (e) {
                    reject(e);
                }
            });
            while (!(this.xAtlas.api ? yield this.xAtlas.api.loaded : false)) {
                yield new Promise(r => setTimeout(r, 100)); // wait for load just in case
            }
            this._libraryLoaded = true;
        });
    }
    /**
     * Pack multiple geometry into a single atlas
     * @param THREE
     * @param nodeList - list of geometries to unwrap
     * @param outputUv - Attribute to write the output uv to
     * @param inputUv - Attribute to write the input uv to (if any)
     */
    packAtlas(nodeList, outputUv = 'uv2', inputUv = 'uv') {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._libraryLoaded) {
                console.warn('xatlas-three: library not loaded');
                return [];
            }
            if (!nodeList)
                return [];
            if (nodeList.length < 1)
                return [];
            const useUvs = this.chartOptions.useInputMeshUvs;
            while (this._isUnwrapping) {
                console.log("xatlas-three: unwrapping another mesh, waiting 100 ms");
                yield new Promise(r => setTimeout(r, 100));
            }
            // if(!(xAtlas.loaded)) { // when not using worker. todo
            // xAtlas.addOnLoad(proxy(()=>xAtlasUnWrapLiteGLMeshes(nodeList, onFinish, chartOptions, packOptions, useNormals, useUvs, resultAttribute, originalAttribute)));
            // return;
            // }
            this._isUnwrapping = true;
            yield this.xAtlas.api.setProgressLogging(this.logProgress);
            yield this.xAtlas.api.createAtlas();
            let meshAdded = [];
            let tag = ""; // for time logging
            for (let mesh of nodeList) {
                let { uuid, index, attributes } = mesh;
                const scaled = mesh.userData.worldScale || 1; // can be [number, number, number] or number
                // if (unwrap === false) continue;
                meshAdded.push(uuid);
                if (!index || !attributes.position || attributes.position.itemSize !== 3) {
                    console.warn("xatlas-three: Geometry not supported: ", mesh);
                    continue;
                }
                tag = "Mesh" + meshAdded.length + " added to atlas: " + uuid;
                // console.log(typeof index.array)
                if (this.timeUnwrap)
                    console.time(tag);
                yield this.xAtlas.api.addMesh(index.array, attributes.position.array, attributes.normal ? attributes.normal.array : undefined, attributes.uv ? attributes.uv.array : undefined, uuid, this.useNormals, useUvs, scaled);
                if (this.timeUnwrap)
                    console.timeEnd(tag);
            }
            tag = "Generated atlas with " + meshAdded.length + " meshes";
            if (this.timeUnwrap)
                console.time(tag);
            let meshes = yield this.xAtlas.api.generateAtlas(this.chartOptions, this.packOptions, true);
            if (this.timeUnwrap)
                console.timeEnd(tag);
            let ret = [];
            for (let m of meshes) {
                /**
                 * @type {Mesh}
                 */
                let mesh = nodeList.find(n => n.uuid === m.mesh);
                if (!mesh) {
                    console.error("xatlas-three: Mesh not found: ", m.mesh);
                    continue;
                }
                // if(mesh.getAttribute("position"))
                //     mesh.deleteAttribute("position");
                // if(mesh.getAttribute("normal") && m.vertex.normal)
                //     mesh.deleteAttribute("normal");
                // if(mesh.getAttribute("uv") && m.vertex.uv)
                //     mesh.deleteAttribute("uv");
                // if(mesh.getAttribute("uv2"))
                //     mesh.deleteAttribute("uv2");
                // if(mesh.getIndex())
                //     mesh.setIndex(null);
                if (m.vertex.vertices)
                    mesh.setAttribute('position', new this.THREE.BufferAttribute(m.vertex.vertices, 3, false));
                if (m.vertex.normals)
                    mesh.setAttribute('normal', new this.THREE.BufferAttribute(m.vertex.normals, 3, true));
                if (m.vertex.coords1)
                    mesh.setAttribute(outputUv, new this.THREE.BufferAttribute(m.vertex.coords1, 2, false));
                if (m.vertex.coords && outputUv !== inputUv)
                    mesh.setAttribute(inputUv, new this.THREE.BufferAttribute(m.vertex.coords, 2, false));
                if (m.index)
                    mesh.setIndex(new this.THREE.BufferAttribute(m.index, 1, false));
                ret.push(mesh);
            }
            yield this.xAtlas.api.destroyAtlas();
            this._isUnwrapping = false;
            return ret;
        });
    }
    /**
     * Unwraps a geometry to generate uv
     * @param geometry
     * @param outputUv
     * @param inputUv
     */
    unwrapGeometry(geometry, outputUv = 'uv', inputUv = 'uv2') {
        return __awaiter(this, void 0, void 0, function* () {
            return this.packAtlas([geometry], outputUv, inputUv);
        });
    }
}
