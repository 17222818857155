import { OrbitControls, MeshReflectorMaterial, Environment, useFBX, useTexture, useGLTF, SpotLight } from '@react-three/drei';
import { Canvas, ObjectMap, ThreeEvent, useFrame } from '@react-three/fiber'
import { EffectComposer, DepthOfField } from '@react-three/postprocessing';
import { useCallback, useEffect, useRef } from 'react';
import { DoubleSide, Group, PointLight} from "three";
import * as THREE from 'three'
import { useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import model from "../anim/UnfoldingCube.gltf"
import { off } from 'process';

import type { OrbitControls as OrbitControlsImpl } from 'three-stdlib'
type GLTFResult = GLTF & ObjectMap

type ActionName = 'Cube'
type GLTFActions = Record<ActionName, THREE.AnimationAction>
    
useGLTF.preload(model)

const Scene = () => {
    const unfolded = useRef<Group>(null);

    //@ts-ignore
    const { nodes, materials, animations } = useGLTF(model) as GLTFResult
    
    const { actions } = useAnimations(animations, unfolded)
    let playAnimation = false;
    materials.Material.side = DoubleSide;
    let opened = false;
    useFrame(({ clock }) => {
        if(unfolded)
            if(playAnimation )
            {
                if(actions["ArmatureAction"]?.time! > 1.3 && actions["ArmatureAction"]?.timeScale != 0)
                {
                    actions["ArmatureAction"]?.setEffectiveTimeScale(0);
                }

                if(actions["ArmatureAction"]?.time! > 0.8 && unfolded.current!.position.y > -0.55)
                {
                  unfolded.current!.position.y -= 0.01;
                }
                if(unfolded.current!.position.y <= -0.54 && !opened)
                {
                  window.open("/intro","_self");
                  playAnimation = false;
                  opened = true;
                }
                
            }
        }
      )
    const launchAnimation= useCallback((e:ThreeEvent<PointerEvent>) =>
    {
        if(!playAnimation)
        {
            playAnimation = true;
            actions["ArmatureAction"]?.setLoop(THREE.LoopOnce,0);
            //@ts-nocheck
            actions["ArmatureAction"]?.play();
        }

    },[]);
    return (
        <group ref ={unfolded} onPointerDown = {launchAnimation}>
            <group name="Armature" position={[0, -1.02, 0]}>
                <primitive object={nodes.Bone} />
                {/* @ts-ignore */}
                <skinnedMesh name="Cube" geometry={nodes.Cube.geometry} material={materials.Material} skeleton={nodes.Cube.skeleton} />
            </group>
        </group>
    );
};

export const HomePage = () =>{

    const controls = useRef<OrbitControlsImpl>(null);


  return (<>
    <div id="clickCube">
        <span>Click on the cube and start unfolding !</span>
    </div>

    <div id="homepage">

        <Canvas
          camera={{
            position: [-3, 4, 8],
            fov: 60,
            near: 0.01,
            far: 1000,
          }}
        >
          <mesh rotation={[-Math.PI / 2, 0, 0]} position ={[0,-0.5,0]}>
            <planeGeometry  args={[1000,1000]}/>
            <MeshReflectorMaterial
              blur={[300, 100]}
              resolution={2048}
              mixBlur={1}
              roughness={0.2}
              mirror={1}/>
          </mesh>

          <EffectComposer>
            <DepthOfField focusDistance={0} focalLength={0.5} bokehScale={2} height={480} />
          </EffectComposer>
          {/* 
// @ts-ignore */}
          <OrbitControls ref={controls} />
          <color attach="background" args={["#A7C7E7"]} />
            <Scene></Scene>
            <Environment preset={"city"}></Environment>
          </Canvas>
          <div id="sideLanding">
            <div id="sideLandingContent">
              <h1>
                UV Game
              </h1>
              <h2>
                Come and learn about UV mapping in an intuitive way
              </h2>
              <p>You will learn the fundamentals you need to unwrap your own 3D models and you will practice having set goals at the end !</p>
            </div>
          </div>
    </div>
    </>
  );
};
